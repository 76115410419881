import React from 'react';
import Notification from "react-notifications-component";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import Routes from "../routes";

const App = props => {

	const theme = createMuiTheme({
		palette: {
			primary: {
				main: "#18ad5a",
				contrastText: "#fff"
			},
			secondary: {
				main: "#333"
			}
		}
	});

	return (
		<ThemeProvider theme={theme}>
			<Notification />
			<Routes />
		</ThemeProvider>
	);
};

export default App;

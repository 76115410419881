import React from "react";
import { Route, Redirect } from "react-router-dom";

import Template from "../container/Template";
import Dashboard from "../pages/Dashboard";
import TownHall from "../pages/TownHall";
import MyProfile from "../pages/MyProfile";

const TownHallRoutes = props => 
    <Template>
        <Route path="/" exact component={TownHall} />
        <Route path="/meu-perfil" exact component={MyProfile} />
        <Redirect to="/" />
    </Template>

export default TownHallRoutes;
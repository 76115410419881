import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox, Typography, Grid, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { showMessage } from "../../utils/errors";

const styles = makeStyles({
    errorMessage: {
        color: "#ff0000"
    }
});

export default props => {

    const classes = styles();
    const { 
        name, 
        rules, 
        onClick,
        label,
        linklabel,
        ...values 
    } = props;

    const { control, errors, setValue } = useFormContext(); 

    //Todo: Adjust here to set value with defaultValues
    return (
        <Grid container direction="column">
            <Grid container item alignItems="center" xs={12}>
                <Controller 
                    name={name}
                    control={control}
                    render={({ onChange, onBlur, value, name }) => (
                        <Checkbox 
                            {...values} 
                            onChange={e => onChange(e.target.checked)}
                            value={value ? value : false}
                            name={name}
                        />
                    )}
                    defaultValue={false}
                    rules={rules}
                />
                {
                    label && <Typography variant="body1">
                        {label} 
                        {
                            linklabel && <Link href="#" onClick={e => onClick(e)}>{linklabel}</Link>
                        }
                    </Typography>
                }
            </Grid>
            <Grid container item alignItems="center" xs={12}>
                <Typography variant="caption" className={classes.errorMessage}>
                    {showMessage(rules, name, errors)}
                </Typography>
            </Grid>
        </Grid>
    );

};
import HttpRequest from "./HttpRequest";
import { LOGIN_URL } from "../utils/url";

class SingInService extends HttpRequest {

    constructor(){
        super(null, LOGIN_URL);
    }
};

export default SingInService;
import React from "react";
import { Route, Redirect } from "react-router-dom";

import Users from "../pages/Users";
import Dashboard from "../pages/Dashboard";
import TownHall from "../pages/TownHall";
import MyProfile from "../pages/MyProfile";
import Template from "../container/Template";

const AuthRoutes = props => 
    <Template>
        <Route path="/" exact component={Dashboard} />
        <Route path="/prefeitura" exact component={TownHall} />
        <Route path="/meu-perfil" exact component={MyProfile} />
        <Route path="/usuarios" exact component={Users} />
        <Redirect to="/" />
    </Template>

export default AuthRoutes;
import { Pannellum } from "pannellum-react";
import NotFound from "../../components/NotFound";
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

const Photo360 = props => {

    const { imagePath } = useParams();
    const history = useHistory();

    const [show, setShow] = useState(true);
    const [imageUrl, setImageUrl] = useState('');
    const [dimensions, setDimension] = useState({
        height: 0,
        width: 0,
        paddingLeft: 0
    });

    const init = async () => {
        setDimension({
            height: document.getElementsByClassName("MuiAppBar-root")[0]?.clientHeight,
            width: window.outerWidth
        });

        if(!imagePath)
            history.push("/");

        let imageName = imagePath.split('\\');

        if(imageName.length < 2)
            history.push("/");

        const finalUrl = `${process.env.REACT_APP_BASE_URL_PHOTO}${imageName[imageName.length - 2]}/${imageName[imageName.length - 1]}`;

        setImageUrl(finalUrl);
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <React.Fragment>
            {
                show ? <div id="viewer" style={{
                        height: `calc(100vh - ${(dimensions.height+3)}px)`,
                        width: dimensions.width
                    }}
                >
                    <Pannellum 
                        width="100%"
                        height="100%"
                        image={imageUrl}
                        pitch={10}
                        yaw={180}
                        hfov={110}
                        autoLoad
                        onError={err => {
                            setShow(false);
                        }}
                    />
                </div> : <NotFound />
            }
        </React.Fragment>
    );
};

export default Photo360;
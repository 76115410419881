export const isLogged = () => {
    const token = getToken();
    return token !== '' && token !== null ? true : false; 
};

export const getToken = () => {
    const tokenLocal = checkLocalStorage();
    const tokenSession = checkSessionStorage();
    return tokenLocal || tokenSession;
};

export const getUserData = () => {
    let data = null;
    const sessionData = sessionStorage.getItem(process.env.REACT_APP_KEY);
    if(sessionData !== null && sessionData !== '')
        data = JSON.parse(sessionData);
    else {
        const localData = localStorage.getItem(process.env.REACT_APP_KEY);
        if(localData !== null && localData !== '')
            data = JSON.parse(localData);
    }   

    return data;
};

export const clearStorage = () => {
    sessionStorage.clear();
    localStorage.clear();
};

export const checkLocalStorage = () => {
    const user = localStorage.getItem(process.env.REACT_APP_KEY);
    
    if(user === null || user === undefined)
        return null;

    const { token } = JSON.parse(user);
    return token;
};

export const checkSessionStorage = () => {
    const user = sessionStorage.getItem(process.env.REACT_APP_KEY);
    
    if(user === null || user === undefined)
        return null;

    const { token } = JSON.parse(user);
    return token;
};
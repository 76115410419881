import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from '@material-ui/lab';
import notification from "../../utils/notification";
import { WYSIWYG } from "../../components";
import NotificationTemplateService from "../../services/NotificationTemplateService";

const styles = makeStyles(theme => ({
    main: {
        [theme.breakpoints.up("sm")]: {
            marginLeft: "58px"
        },
        padding: "30px"
    }
}));

const Notification = props => {

    const classes = styles();
    const [loading, setLoading] = useState(true);
    const [dimensions, setDimensions] = useState([]);
    const [error, setError] = useState('');
    const [template, setTemplate] = useState('');

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setDimensions({
            height: document.getElementsByClassName("MuiAppBar-root")[0].clientHeight,
            width: window.outerWidth > 550 ? (window.outerWidth - 58) : window.outerWidth
        });
        try {

            const respTemplate = await new NotificationTemplateService().get();

            if ((respTemplate.status === 200) && (respTemplate.data.text)) {
                setTemplate(JSON.parse(respTemplate.data.text));
            }
            setLoading(false);

        } catch (err) {

            if (!err.response)
                notification("Ops!", "Falha de conexão", "danger");

        }
    };

    const handleSubmit = async ({ text, html }) => {
        try {

            await new NotificationTemplateService().post({ text, html });

        } catch (err) {

            if (!err.response)
                notification("Ops!", "Falha de conexão", "danger");

        }
    };

    return (
        <Grid container className={classes.main} style={{
            height: `calc(100vh - ${(dimensions.height + 4)}px)`,
            width: "95%"
        }}>
            {!!error.length && (
                <Alert severity="error" style={{ margin: '10px 0' }}>
                    {error}
                </Alert>
            )}
            {!loading &&
                <WYSIWYG
                    handleSubmit={handleSubmit}
                    defaultContent={template}
                    previewTitle="Template de notificação"
                    setError={setError}
                />
            }
        </Grid>
    );
};

export default Notification;
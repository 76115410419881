import HttpRequest from "./HttpRequest";
import promisefy from "../utils/promisefy";
import { WORKSHEET_URL, WORKSHEET_REMOVE_MANY_URL } from "../utils/url";

class WorksheetService extends HttpRequest {

    constructor() {
        super(true, WORKSHEET_URL);
    };

    deleteMany(data) {
        return promisefy(this.request.post(WORKSHEET_REMOVE_MANY_URL, data));
    };
};

export default WorksheetService;
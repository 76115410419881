import React from "react";
import { Grid } from "@material-ui/core";
import { Header, Sidebar } from "../components";

export default props => {

    return (
        <Grid container >
            <Header />
            <Sidebar />
            <div className="main">
                {props.children}
            </div>
        </Grid>
    );

};
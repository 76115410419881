import clsx from 'clsx';
import React from "react";
import PropTypes from "prop-types";
import { Add, Delete, CloudDownload } from "@material-ui/icons";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { Toolbar, Tooltip, Typography, IconButton, Fab } from "@material-ui/core";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    }
}));

const EnhancedTableToolbar = (props) => {
    
    const classes = useToolbarStyles();
    const { numSelected, title, addEvent, deleteEvent, exportAction, hiddenEvents } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} Selecionado(s)
                </Typography>
            ) : (
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        {title}
                    </Typography>
                )}

            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton 
                        aria-label="delete"
                        onClick={deleteEvent}
                    >
                        <Delete />
                    </IconButton>
                </Tooltip>
            ) : (
                <React.Fragment>
                    {(exportAction)?
                        <Tooltip title="Excel">
                            <IconButton 
                                aria-label="download" 
                                style={{ marginRight: "10px" }}
                                onClick={() => exportAction()}    
                            >
                                <CloudDownload fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    :false}
                    {(addEvent)?
                        <Tooltip title="Incluir Novo">
                            <Fab 
                                color="primary" 
                                aria-label="incluir novo"
                                size="small"
                                onClick={() => addEvent()}
                            >
                                <Add />
                            </Fab>
                        </Tooltip>
                    :false}
                </React.Fragment>
            )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default EnhancedTableToolbar;
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, CssBaseline, Card, CardHeader, CardContent, Typography } from "@material-ui/core";

import Logo from "../../../src/assets/images/vargem-logo.png";

const style = makeStyles({
    cardTitle: {
        textAlign: "center"
    },
    logo: {
        paddingTop: "35px",
        width: "300px"
    },
    termMargin: {
        margin: "35px 0"
    }
});

const Terms = () => {

    const classes = style();

    return <Grid container justify="center" className={classes.root}>
                <CssBaseline />
                <Grid container justify="center" item sm={12}>
                    <img src={Logo} alt="Logo" className={classes.logo} />
                </Grid>
                <Grid item xs={10} sm={6}>
                    <Card className={classes.termMargin}>
                        <CardHeader title="Termos de Uso" className={classes.cardTitle} />
                        <CardContent>
                            <Typography variant="body1">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi enim est, cursus at enim in, ornare hendrerit nisi. Morbi condimentum vehicula blandit. Cras sollicitudin non purus eget vehicula. Quisque commodo sapien vel arcu iaculis, id semper ligula maximus. Ut euismod, libero ac pellentesque pharetra, lorem libero viverra nisi, sit amet tincidunt neque enim sed sem. Sed finibus eget odio non fermentum. Aliquam erat volutpat. Mauris varius luctus ipsum sed pharetra.
                        </Typography>
                            <Typography variant="body1">
                                Sed ultricies tellus nisi, egestas fermentum mauris fermentum id. Suspendisse sem lectus, faucibus eu aliquam in, eleifend mattis nisi. Nullam rutrum est et scelerisque porttitor. Cras vitae tempor enim, non tincidunt odio. Aenean sit amet velit ornare, finibus sem at, maximus dui. Aenean lacinia lorem vel quam facilisis, sed porttitor lorem convallis. Maecenas gravida magna id luctus vehicula. Integer bibendum a neque sit amet fringilla. Suspendisse potenti.
                        </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
};

export default Terms;
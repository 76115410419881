import HttpRequest from "./HttpRequest";
import { REGISTER_URL } from "../utils/url";

class SingUpService extends HttpRequest {

    constructor() {
        super(null, REGISTER_URL);
    }

};

export default SingUpService;
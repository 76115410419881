import React from 'react';
import HtmlToReactParser from 'html-to-react';

const Preview = props => {
  const { content } = props;

  const { Parser } = HtmlToReactParser;
  const parser = new Parser();

  const parsed = parser.parse(content);

  return <>{parsed}</>;
};

export default Preview;

import React, { useState } from 'react';
import { Button, Dialog, TextField, DialogTitle, DialogActions, 
    DialogContent, DialogContentText, CircularProgress } from '@material-ui/core';

import { Form, Input } from "../";

const DialogBox = props => {
    return <Dialog 
        open={props.open} 
        onClose={props.onClose} 
        aria-labelledby="form-dialog-title"
    >
        <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
        <Form submit={props.onSubmit}>
            <DialogContent>
                <DialogContentText>{props.description}</DialogContentText>
                <Input 
                    id="password"
                    type="password"
                    name="password"
                    label="Senha"
                    fullWidth
                    variant="outlined"
                    required
                    rules={{
                        required: true
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    {props.closeLabel}
                </Button>
                <Button
                    type="submit"
                    color="primary"
                >
                    {(props.loading) ? <CircularProgress/> : props.submitLabel}
                </Button>
            </DialogActions>
        </Form>
    </Dialog>
};

export default DialogBox;
import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Login from "../pages/Login";
import SingUp from "../pages/SingUp";
import Terms from "../pages/Terms";
import Photo360 from "../pages/Photo360";
import RequestResetPassword from "../pages/RequestResetPassword";
import ResetPassword from "../pages/ResetPassword";
import SingUpMessage from "../pages/SingUpMessage";
import AccountActivation from "../pages/AccountActivation";
import TownHallRoutes from "./TownHallRoutes";
import PublicRoutes from "./PublicRoutes";
import AdminRoutes from "./AdminRoutes";
import NotificationRoutes from "./NotificationRoutes";

import { isLogged, getUserData } from "../utils/user";

export default props => {

    const userData = getUserData();

    const GetRoutes = () => {

        if(userData.profileId == 1)
            return <AdminRoutes />

        if(userData.profileId == 2)
            return <TownHallRoutes />

        if(userData.profileId == 3)
            return <PublicRoutes />

        if(userData.profileId == 4)
            return <NotificationRoutes />
    };

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" exact component={Login} />
                <Route path="/registro" exact component={SingUp} />
                <Route path="/termos-de-uso" exact component={Terms} />
                <Route path="/recuperar-senha" exact component={RequestResetPassword} />
                <Route path="/redefinir-senha/:token" exact component={ResetPassword} />
                <Route path="/mensagem-registro" exact component={SingUpMessage} />
                <Route path="/ativacao/:token" exact component={AccountActivation} />
                <Route path="/visualizador/:imagePath" component={Photo360} />
                {
                    isLogged() ? <GetRoutes /> : <Redirect to="/login" />
                }
            </Switch>
        </BrowserRouter>
    );
};

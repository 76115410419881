import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { Visibility } from '@material-ui/icons';

import { withStyles, Grid, Button, CircularProgress, Typography } from '@material-ui/core';

import SubmitButton from '../SubmitButton';

import styles from './WYSIWYGStyle-jss';

import PreviewModal from './PreviewModal';

import { CustomDropdown } from './CustomButtons';

import notification from "../../utils/notification";

import '../../assets/css/react-draft-wysiwyg.css';

import elements from './elements'

const WYSIWYG = props => {
  const {
    classes,
    handleSubmit,
    defaultContent,
    previewTitle,
    PreviewComponent,
    setError,
  } = props;

  const editorRef = useRef();

  const [preview, setPreview] = useState('');
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customElements, setCustomElements] = useState(elements);

  const handlePreview = () => {
    setIsPreviewOpen(open => !open);

    const editorData = editorRef.current.state.editorState;

    const previewData = draftToHtml(
      convertToRaw(editorData.getCurrentContent())
    );

    setPreview(previewData);
  };

  const onSubmit = () => {
    setLoading(true);
    const { editorState } = editorRef.current.state;

    const raw = convertToRaw(editorState.getCurrentContent());

    const text = JSON.stringify(raw);

    const html = draftToHtml(raw);

    handleSubmit({ text, html });
    notification("Sucesso", "Template atualizado", "success");
    setLoading(false);
  };


  const onStateChange = () => {
    if (setError) {
      setError('');
    }
  };

  const customButtons = useCallback(
    customElements => {
      if (customElements && customElements.length) {
        return customElements.map(element => {
          if (element.customDropdown) {
            return (
              <CustomDropdown
                title={element.customDropdown.title}
                options={element.customDropdown.options}
              />
            );
          }
        });
      }

      return [];
    },
    [customElements]
  );


  const defaultEditorState = useCallback(
    defaultContent => {
      const defaultContentBlock = defaultContent
        ? convertFromRaw(defaultContent)
        : null;

      const result = defaultContentBlock
        ? EditorState.createWithContent(defaultContentBlock)
        : EditorState.createEmpty();

      return result;
    },
    [defaultContent]
  );

  return (
    <Grid container direction="column" justify="flex-start">
      <Grid item>
      <Typography variant="h3" component="h2" style={{paddingBottom: "40px"}}>
        Template de notificação
      </Typography>
        <Editor
          ref={editorRef}
          defaultEditorState={defaultEditorState(defaultContent)}
          onEditorStateChange={onStateChange}
          editorClassName={classes.textEditor}
          toolbarClassName={classes.toolbarEditor}
          toolbarCustomButtons={customButtons(customElements)}
        />
      </Grid>

      <Grid item>
        <div style={{paddingTop: "10px", paddingBottom: "50px"}}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="flex-end"
          >
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={handlePreview}
                className={classes.previewButton}
              >
                Visualizar <Visibility style={{ marginLeft: 10 }} />
              </Button>
            </Grid>

            <Grid item>
              <SubmitButton
                variant="contained"
                type="button"
                handleClick={onSubmit}
                color="primary"
                className={classes.saveButton}
              >
                {(loading) ? <CircularProgress/> : "Salvar"}
              </SubmitButton>
            </Grid>
          </Grid>
        </div>
      </Grid>

      <PreviewModal
        isOpen={isPreviewOpen}
        handleClose={() => setIsPreviewOpen(false)}
        content={preview}
        previewTitle={previewTitle}
        PreviewComponent={PreviewComponent}
      />
    </Grid>
  );
};

WYSIWYG.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  defaultContent: PropTypes.any,
  previewTitle: PropTypes.string,
  PreviewComponent: PropTypes.any,
  custonElements: PropTypes.array,
  setError: PropTypes.func,
};

export default withStyles(styles)(WYSIWYG);

import React from "react";
import { FormProvider, useForm } from "react-hook-form";

const Form = props => {

    const methods = useForm({
        defaultValues: props.defaultValues
    });

    return (
        <FormProvider {...methods} >
            <form 
                onSubmit={methods.handleSubmit(props.submit)} 
                className={props.className} 
                noValidate
            >
                {props.children}
            </form>
        </FormProvider>
    );
};

export default Form;
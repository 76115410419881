import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Link, Grid, Typography, CssBaseline, Container } from '@material-ui/core';

import Logo from "../../../src/assets/images/vargem-logo.png";
import { Form, Input, Select, CheckBox, Copyright, Button } from "../../components";

import CityService from "../../services/CityService";
import SingUpService from "../../services/SingUpService";

import ufs from "../../data/uf";
import notification from "../../utils/notification";
import { isValidCPF, removePonctuation } from "../../utils/utils"

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        fontWeight: 600
    },
    logo: {
        width: "300px",
        padding: "0px 0px 35px 0px"
    },
    copyright: {
        position: "absolute",
        bottom: 10,
        right: 10
    }
}));

const SingUp = () => {

    const classes = useStyles();
    const history = useHistory();
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleClick = event => {
        event.preventDefault();
        history.push("/login");
    };

    const handleTerms = event => {
        event.preventDefault();
        window.open(`${window.location.origin}/termos-de-uso`, "_blank");
    };

    const getCities = async param => {
        const response = await new CityService().get(param);
        
        if(response.status !== 200){
            setCities([]);
            return;
        }

        const resp = response.data.object.map(item => {
            return {
                value: item.id,
                label: item.name
            };
        })
        setCities(resp);
    };

    const changeUF = value => getCities(value);

    const save = async values => {

        setLoading(true);
        const { ...data } = values;

        data.cpf = removePonctuation(values.cpf);
        data.telephone = removePonctuation(values.telephone);
        data.cellphone = removePonctuation(values.cellphone);
        data.password = values.password.trim();
        data.number = values.number.trim();
        data.zipcode = removePonctuation(values.zipcode);
        data.url = `${window.location.origin}/ativacao/`;

        try {
            const response = await new SingUpService().post(data);

            if(response.status === 200){
                const { title, message, type, status } = response.data;
                notification(title, message, type);

                if(status)
                    history.push("/mensagem-registro");
                else
                    setLoading(false);
            }
        } catch (error) {
            notification("Ops!", "Falha ao cadastrar novo usuário", "danger");
            setLoading(false);
        };
    };

    return (
        <React.Fragment>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={Logo} alt="Logo Prefeitura" className={classes.logo} />
                    <Typography component="h1" variant="h5">
                        Registre-se
                    </Typography>
                    <Form 
                        submit={save} 
                        className={classes.form}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Input 
                                    autoFocus
                                    id="name"
                                    name="name"
                                    label="Nome"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    rules={{
                                        required: true,
                                        minLength: 3,
                                        maxLength: 50
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input
                                    id="cpf"
                                    name="cpf"
                                    label="CPF"
                                    type="mask"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    mask="999.999.999-99"
                                    rules={{
                                        validate: value => isValidCPF(value) === true,
                                        validateType: "CPF"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    id="email"
                                    name="email"
                                    label="Email"
                                    type="text"
                                    fullWidth
                                    required
                                    variant="outlined"
                                    rules={{
                                        required: true,
                                        pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                                        validateType: "Email"
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input 
                                    id="password"
                                    type="password"
                                    name="password"
                                    label="Senha"
                                    fullWidth
                                    required
                                    variant="outlined" 
                                    rules={{
                                        required: true,
                                        minLength: 8,
                                        maxLength: 14
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input 
                                    id="telephone"
                                    name="telephone"
                                    label="Telefone"
                                    type="mask"
                                    variant="outlined"
                                    fullWidth
                                    mask="(99) 9999-9999"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input
                                    id="cellphone"
                                    name="cellphone"
                                    label="Celular"
                                    type="mask"
                                    required
                                    variant="outlined"
                                    fullWidth
                                    mask="(99) 99999-9999"
                                    rules={{
                                        required: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Select 
                                    id="state"
                                    name="state"
                                    labelId="state-label"
                                    label="UF"
                                    options={ufs}
                                    onChange={changeUF}
                                    required
                                    rules={{
                                        required: true
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Select 
                                    id="cityId"
                                    name="cityId"
                                    labelId="cityId-label"
                                    label="Cidade"
                                    required
                                    rules={{}}
                                    options={cities}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    id="street"
                                    name="street"
                                    label="Logradouro"
                                    type="text"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                    rules={{
                                        required: true,
                                        minLength: 3,
                                        maxLength: 100
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input
                                    id="number"
                                    name="number"
                                    label="Número"
                                    type="mask"
                                    variant="outlined"
                                    fullWidth
                                    mask="9999"
                                    hidemask
                                    maskChar={' '}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Input
                                    id="neighborhood"
                                    name="neighborhood"
                                    label="Bairro"
                                    type="text"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                    rules={{
                                        required: true,
                                        minLength: 3,
                                        maxLength: 100
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    id="zipcode"
                                    name="zipcode"
                                    label="CEP"
                                    type="mask"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    mask="99999-999"
                                    rules={{
                                        required: true
                                    }}
                                />
                            </Grid>
                            <Grid container item alignItems="center" xs={12}>
                                <CheckBox
                                    name="terms" 
                                    value="allowExtraEmails" 
                                    color="primary" 
                                    label="Li e aceito os "
                                    linklabel="Termos de uso"
                                    onClick={handleTerms}
                                    rules={{
                                        required: true
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            label="Registrar"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            loading={loading}
                        />
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link href="#" onClick={handleClick} variant="body2">
                                    Já possui uma conta ? Entre aqui
                                </Link>
                            </Grid>
                        </Grid>
                    </Form>
                </div>
            </Container>
            <Grid container justify="flex-end" style={{ padding: "10px" }}>
                <Copyright />
            </Grid>
        </React.Fragment>
    );
};

export default SingUp;
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, CssBaseline, Container } from '@material-ui/core';
import { Delete } from '@material-ui/icons';

import { Form, Input, Select, DialogBox } from "../../components";

import CityService from "../../services/CityService";
import UserService from "../../services/UserService";

import ufs from "../../data/uf";
import notification from "../../utils/notification";
import { getUserData, clearStorage } from "../../utils/user";
import { isValidCPF, removePonctuation } from "../../utils/utils";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

const MyProfile = () => {

    const classes = useStyles();
    const history = useHistory();
    const userData = getUserData();
    const [cities, setCities] = useState([]);
    const [open, setOpen] = useState(false);
    const [defaultValues, setDefaultValues] = useState(null);

    useEffect(() => {
        getUser();
    }, []);

    const closeDialog = () => setOpen(false);

    const openDialog = () => setOpen(true);

    const getUser = async () => {
        const { secureId } = getUserData();

        const response = await new UserService().get(secureId);

        if(response.status !== 200){
            notification("Ops", "Falha ao obter dados do perfil", "danger");
            return;
        }

        const { ...object } = response.data.object[0];

        const user = {
            secureId: object.secureId,
            name: object.name,
            cpf: object.cpf,
            email: object.email,
            password: object.password,
            telephone: object.telephone,
            cellphone: object.cellphone,
            state: object.city.state,
            cityId: object.cityId,
            street: object.street,
            number: object.number,
            neighborhood: object.neighborhood,
            zipcode: object.zipcode
        };

        getCities(user.state);
        setDefaultValues(user);
    };

    const getCities = async param => {
        const response = await new CityService().get(param);
        
        if(response.status !== 200){
            setCities([]);
            return;
        }

        const resp = response.data.object.map(item => {
            return {
                value: item.id,
                label: item.name
            };
        })
        setCities(resp);
    };

    const changeUF = value => getCities(value);

    const save = async values => {

        const { ...data } = values;

        data.cpf = removePonctuation(values.cpf);
        data.telephone = removePonctuation(values.telephone);
        data.cellphone = removePonctuation(values.cellphone);
        data.password = values.password.trim();
        data.number = values.number.trim();
        data.zipcode = removePonctuation(values.zipcode);

        try {

            const response = await new UserService().post(data);

            if(response.status === 200)
                notification("Sucesso!", "Perfil Atualizado com sucesso", "success");
            
        } catch (error) {
            notification("Ops!", "Falha ao atualizar perfil", "danger");
        };
    };

    const deleteAccount = async values => {

        const { password } = values;

        try {
            const response = await new UserService().deleteAccount({
                password
            });

            if(response.status === 200){
                notification("Sucesso!", "Sua conta foi removida", "success");
                clearStorage();
                history.push("/login");
            }

        } catch(error) {
            if(!error.response){
                notification("Ops!", "Falha de conexão", "danger");
                return;
            }

            if(error.response.status === 401){
                notification("Ops!", "Senha incorreta!", "warning");
                return;
            }
        }
    };

    return (
        <Grid container justify="center">
            <Grid item xs={10} sm={6}>
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5">
                        Meu Perfil
                    </Typography>
                    {
                        defaultValues && <Form 
                            submit={save} 
                            className={classes.form}
                            defaultValues={defaultValues}
                        >
                            <Input 
                                type="hidden"
                                name="secureId"
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Input 
                                        autoFocus
                                        id="name"
                                        name="name"
                                        label="Nome"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        rules={{
                                            required: true,
                                            minLength: 3,
                                            maxLength: 50
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        id="cpf"
                                        name="cpf"
                                        label="CPF"
                                        type="mask"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        mask="999.999.999-99"
                                        rules={{
                                            validate: value => isValidCPF(value) === true,
                                            validateType: "CPF"
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        id="email"
                                        name="email"
                                        label="Email"
                                        type="text"
                                        fullWidth
                                        required
                                        variant="outlined"
                                        disabled
                                        rules={{
                                            required: true,
                                            pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                                            validateType: "Email"
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input 
                                        id="password"
                                        type="password"
                                        name="password"
                                        label="Senha"
                                        fullWidth
                                        required
                                        variant="outlined" 
                                        rules={{
                                            minLength: 8,
                                            maxLength: 14
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input 
                                        id="telephone"
                                        name="telephone"
                                        label="Telefone"
                                        type="mask"
                                        variant="outlined"
                                        fullWidth
                                        mask="(99) 9999-9999"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        id="cellphone"
                                        name="cellphone"
                                        label="Celular"
                                        type="mask"
                                        required
                                        variant="outlined"
                                        fullWidth
                                        mask="(99) 99999-9999"
                                        rules={{
                                            required: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Select 
                                        id="state"
                                        name="state"
                                        labelId="state-label"
                                        label="UF"
                                        options={ufs}
                                        onChange={changeUF}
                                        required
                                        rules={{
                                            required: true
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Select 
                                        id="cityId"
                                        name="cityId"
                                        labelId="cityId-label"
                                        label="Cidade"
                                        required
                                        rules={{}}
                                        options={cities}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        id="street"
                                        name="street"
                                        label="Logradouro"
                                        type="text"
                                        variant="outlined"
                                        required
                                        fullWidth
                                        inputProps={{
                                            maxLength: 100
                                        }}
                                        rules={{
                                            required: true,
                                            minLength: 3,
                                            maxLength: 100
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        id="number"
                                        name="number"
                                        label="Número"
                                        type="mask"
                                        variant="outlined"
                                        fullWidth
                                        mask="9999"
                                        hidemask
                                        maskChar={' '}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Input
                                        id="neighborhood"
                                        name="neighborhood"
                                        label="Bairro"
                                        type="text"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        inputProps={{
                                            maxLength: 100
                                        }}
                                        rules={{
                                            required: true,
                                            minLength: 3,
                                            maxLength: 100
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        id="zipcode"
                                        name="zipcode"
                                        label="CEP"
                                        type="mask"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        mask="99999-999"
                                        rules={{
                                            required: true
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container justify={userData.profileId != 3 ? "flex-end" : "space-between" }>
                                {
                                    userData.profileId == 3 && <Button
                                        type="button"
                                        variant="contained"
                                        color="secondary"
                                        className={classes.submit}
                                        startIcon={<Delete />}
                                        onClick={openDialog}
                                    >
                                        Apagar Conta
                                    </Button>
                                }
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Atualizar Perfil
                                </Button>
                            </Grid>
                        </Form>
                    }
                </div>
                <DialogBox 
                    open={open}
                    onClose={closeDialog}
                    title="Atenção!"
                    description="Você está prestes a apagar sua conta, todos os seus dados serão excluídos do sistema, para continuar digite sua senha, no campo abaixo:"
                    closeLabel="Cancelar"
                    submitLabel="Confirmar"
                    onSubmit={deleteAccount}
                />
            </Grid>
        </Grid>
    );
};

export default MyProfile;
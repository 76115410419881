import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { Grid, Paper, TextField } from "@material-ui/core";
import { getToken } from "../../utils/user";

import moment from 'moment';
import notification from "../../utils/notification";
import { DataTable, DialogBox, Button } from "../../components";
import WorksheetService from "../../services/WorksheetService";
import Select from "../../components/Select/selectWithOutForm";

import NotificationService from "../../services/NotificationService";
import NotificationTemplateService from "../../services/NotificationTemplateService";

const styles = makeStyles(theme => ({
    main: {
        [theme.breakpoints.up("sm")]: {
            marginLeft: "58px"
        },
        padding: "30px"
    },
    buttonSpacing: {
        padding: "0px !important",
        textAlign: "right",
        width: "100%"
    },
    filter: {
        width: "100%",
        height: "200px",
        paddingLeft: "20px",
        paddingTop: "20px",
        marginBottom: "20px"
    },
    bciLimits: {
        paddingLeft: "10px",
        width: "100px"
    }
}));

const columns = [
    { id: 'id', numeric: true, disablePadding: true, label: 'Número da notificação' },
    { id: 'codImovel', numeric: false, disablePadding: true, label: 'Código do imóvel' },
    { id: 'owner', numeric: false, disablePadding: true, label: 'Proprietário' },
    { id: 'bciCompleto', numeric: false, disablePadding: true, label: 'BCI' },
    { id: 'areaTotalEdificada', numeric: false, disablePadding: true, label: 'Área total edificada' },
    { id: 'address', numeric: false, disablePadding: true, label: 'Endereço' },
    { id: 'downloadDate', numeric: false, disablePadding: true, label: 'Data último download' },
    { id: 'downloaduser', numeric: false, disablePadding: true, label: 'Usuário último download' }
];

const situations = [
    {
        value: 'Todos',
        label: 'Todos',
    },
    {
        value: 'Gerados',
        label: 'Gerados',
    },
    {
        value: 'Não Gerados',
        label: 'Não Gerados',
    }
];

const optionsBci1 = [
    {
        value: "Todos",
        label: "Todos",
    }
];
const Worksheet = props => {
    const { id: workSheetId } = useParams()
    const history = useHistory();

    const classes = styles();
    const [dialog, setDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dimensions, setDimensions] = useState([]);
    const [valuesToDelete, setValuesToDelete] = useState([]);
    const [download, setDownload] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [notificationsFiltred, setNotificationsFiltred] = useState([]);
    const [situation, setSituation] = useState("Todos");
    const [bci1, setBci1] = useState("-");
    const [bci2, setBci2] = useState("-");
    const [bci3Min, setBci3Min] = useState('');
    const [bci3Max, setBci3Max] = useState('');
    const [bci4, setBci4] = useState('');
    const [addressOrOwner, setAddressOrOwner] = useState('');
    const [optionsBci1, setOptionsBci1] = useState([{
        value: "-",
        label: "-"
    }])
    const [optionsBci2, setOptionsBci2] = useState([{
        value: "-",
        label: "-"
    }])
    const [codImovel, setCodImovel] = useState("");

    useEffect(() => {
        getData(true);
    }, []);

    const getData = async (initial) => {
        setDimensions({
            height: document.getElementsByClassName("MuiAppBar-root")[0].clientHeight,
            width: window.outerWidth > 600 ? (window.outerWidth - 58) : window.outerWidth
        });
        try {
            const resp = await new NotificationService().notificationTable(workSheetId);

            const allOptions = [{
                value: "Todos",
                label: "Todos"
            }];
            
            const bci1concat = allOptions.concat(resp.data.bci1Options)
            const bci2concat = allOptions.concat(resp.data.bci2Options)

            if (resp.data) {
                setNotifications(resp.data.notifications);
                setNotificationsFiltred(resp.data.notifications);
                setOptionsBci1(bci1concat)
                setOptionsBci2(bci2concat)
                setBci1(bci1concat[0].value)
                setBci2(bci2concat[0].value)
                if(!initial){
                    filter(resp.data.notifications);
                }
            }

            setLoading(false);

        } catch (err) {

            if (!err.response)
                alert("Network error!...");

        }
    };

    const handleDelete = async values => {
        setDialog(true);
        setValuesToDelete(values);
    };

    const closeDialog = () => {
        setDialog(false);
    };

    const deleteRows = async values => {
        const { password } = values;

        const data = {
            password,
            valuesToDelete
        };

        try {

            const response = await new WorksheetService().deleteMany(data);

            if (response.status === 200) {
                notification("Sucesso!", "Linhas removidas com sucesso", "success");
                await getData();
                setValuesToDelete([]);
                setDialog(false);
            }

        } catch (error) {
            console.log(error);
            if (!error.response) {
                notification("Ops!", "Falha de conexão", "danger");
                return;
            }

            if (error.response.status === 401) {
                notification("Ops!", "Senha incorreta!", "warning");
                return;
            }
        }
    };

    const verifyLimits = () => {
        let passou = true;
        if (addressOrOwner === "" && codImovel === "") {
            if (bci2 === "Todos") {
                notification("BCI - Campo 2", "Campo obrigatório", "warning");
                passou = false;
            }
            if (bci3Max === "") {
                notification("BCI - Campo 3 Max", "Campo obrigatório", "warning");
                passou = false;
            }
            if (bci3Min === "") {
                notification("BCI - Campo 3 Min", "Campo obrigatório", "warning");
                passou = false;
            }
            if ((parseInt(bci3Max) - parseInt(bci3Min)) > 50) {
                notification("Limites BCI - Campo 3", "Limite não pode exceder 50", "warning");
                passou = false;
            }
        }
        if (passou) {
            return true;
        } else {
            return false;
        }
    }

    const filter = (notificationsBD) => {
        let itens;
        if (notificationsBD) {
            itens = notificationsBD;
        } else {
            itens = notifications;
        }
        setNotificationsFiltred(itens.map(notification => {
            if ((parseInt(notification.bci1) === parseInt(bci1)) || (bci1 === "Todos")) {
                if ((parseInt(notification.bci2) === parseInt(bci2)) || (bci2 === "Todos")) {
                    if ((parseInt(notification.bci3) >= parseInt(bci3Min)) || (bci3Min === "")) {
                        if ((parseInt(notification.bci3) <= parseInt(bci3Max)) || (bci3Max === "")) {
                            if ((parseInt(notification.bci4) === parseInt(bci4)) || (bci4 === "")) {
                                if (
                                    (situation === "Todos") ||
                                    ((notification.downloadDate !== "-") && (situation === "Gerados")) ||
                                    ((notification.downloadDate === "-") && (situation === "Não Gerados"))
                                ) {
                                    const regex = new RegExp(addressOrOwner, "gi");
                                    if ((regex.exec(notification.owner)) || (regex.exec(notification.address))) {
                                        const regex2 = new RegExp(codImovel, "gi");
                                        if (regex2.exec(notification.codImovel)) {
                                            return notification;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }).filter(item => item !== undefined));
        setDownload(true)
    }


    const downloadPDF = async (setLoadingButton) => {
        if (download) {
            if (!verifyLimits()) {
                return false;
            }
            setLoadingButton(true);
            const urlDownload = `${process.env.REACT_APP_BASE_URL_API}notifications/notificationTable/downloadPDF`;
            var xhr = new XMLHttpRequest();
            xhr.open('POST', urlDownload, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function () {
                if (this.status == 200) {
                    var filename = `notificações-${moment().format("YYYYMMDD-HHmmss")}.pdf`;
                    var disposition = xhr.getResponseHeader('Content-Disposition');
                    if (disposition && disposition.indexOf('attachment') !== -1) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                        var matches = filenameRegex.exec(disposition);
                        if (matches != null && matches[1])
                            filename = matches[1].replace(/['"]/g, '');
                    }
                    var type = xhr.getResponseHeader('Content-Type');

                    var blob = typeof File === 'function'
                        ? new File([this.response], filename, { type })
                        : new Blob([this.response], { type });
                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        var URL = window.URL || window.webkitURL;
                        var downloadUrl = URL.createObjectURL(blob);

                        if (filename) {
                            // use HTML5 a[download] attribute to specify filename
                            var a = document.createElement("a");
                            // safari doesn't support this yet
                            if (typeof a.download === 'undefined') {
                                window.location = downloadUrl;
                            } else {
                                a.href = downloadUrl;
                                a.download = filename;
                                document.body.appendChild(a);
                                a.click();
                            }
                        } else {
                            window.location = downloadUrl;
                        }
                        setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
                    }
                    notification("Sucesso", "PDF gerado", "success");
                } else {
                    notification("Ops!", "Erro ao gerar PDF", "danger");
                }
                setLoadingButton(false);
            };
            xhr.setRequestHeader('Content-type', 'application/json;charset=UTF-8');
            xhr.setRequestHeader('Authorization', `Bearer ${getToken()}`);
            xhr.send(JSON.stringify(
                {
                    notificationIds: notificationsFiltred.map(notification => {
                        return notification.id;
                    })
                }
            ));
        } else {
            notification('Ops!', 'Antes, execute o filtro', 'warning')
        }
    };

    return (
        <Grid container className={classes.main} style={{
            height: `calc(100vh - ${(dimensions.height + 4)}px)`,
            width: "95%"
        }}>
            <Paper elevation={8} className={classes.filter}>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-start"
                >
                    <Grid item>
                        <TextField
                            label="Endereço ou proprietário"
                            style={{ width: "520px", marginLeft: "20px", marginRight: "10px" }}
                            value={addressOrOwner}
                            onChange={(event) => {
                                setAddressOrOwner(event.target.value);
                                setDownload(false)
                            }}
                            variant="outlined"
                        />
                        <TextField
                            label="Código do imóvel"
                            style={{ width: "150px", marginRight: "40px" }}
                            value={codImovel}
                            onChange={(event) => {
                                setCodImovel(event.target.value);
                                setDownload(false)
                            }}
                            variant="outlined"
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "10px", marginLeft: "180px", width: "150px" }}
                            label={"Filtrar"}
                            loading={loading}
                            onClick={() => (verifyLimits()) ? filter() : false}
                        />
                    </Grid>
                    <Grid item style={{ paddingTop: "20px" }}>
                        <Select
                            label="BCI - Campo 1"
                            setOption={setBci1}
                            style={{ width: "150px" }}
                            options={optionsBci1}
                            value={bci1}
                            setDownload={setDownload}
                        />
                        <Select
                            label="BCI - Campo 2"
                            setOption={setBci2}
                            style={{ width: "150px" }}
                            options={optionsBci2}
                            value={bci2}
                            setDownload={setDownload}
                        />
                        <TextField
                            label="BCI - Campo 3 Min"
                            className={classes.bciLimits}
                            style={{ width: "150px", marginLeft: "40px" }}
                            value={bci3Min}
                            onChange={(event) => {
                                setBci3Min(event.target.value);
                                setDownload(false)
                            }}
                            variant="outlined"
                        />
                        <TextField
                            label="BCI - Campo 3 Max"
                            className={classes.bciLimits}
                            style={{ width: "150px" }}
                            value={bci3Max}
                            onChange={(event) => {
                                setBci3Max(event.target.value);
                                setDownload(false)
                            }}
                            variant="outlined"
                        />
                        <TextField
                            label="BCI - Campo 4"
                            className={classes.bciLimits}
                            style={{ width: "150px", marginLeft: "40px" }}
                            value={bci4}
                            onChange={(event) => {
                                setBci4(event.target.value);
                                setDownload(false)
                            }}
                            variant="outlined"
                        />
                        <Select
                            label="Situação"
                            setOption={setSituation}
                            style={{ width: "150px" }}
                            options={situations}
                            value={situation}
                            setDownload={setDownload}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "20px", marginLeft: "20px", width: "150px" }}
                            label={"Voltar"}
                            onClick={() => history.push('/planilhas')}
                        />
                    </Grid>
                </Grid>
            </Paper>
            <DataTable
                columns={columns}
                data={notificationsFiltred}
                title="Notificações"
                deleteAction={handleDelete}
                type="notification"
                onClickRow={() => { }}
                downloadPDF={downloadPDF}
            />
            <DialogBox
                open={dialog}
                onClose={closeDialog}
                title="Atenção!"
                description="Para remover as linhas selecionadas, digite sua senha no campo abaixo:"
                closeLabel="Cancelar"
                submitLabel="Confirmar"
                onSubmit={deleteRows}
            />
        </Grid>
    );
};

export default Worksheet;

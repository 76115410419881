import HttpRequest from "./HttpRequest";
import { CITY_URL } from "../utils/url";

class CityService extends HttpRequest {

    constructor() {
        super(null, CITY_URL);
    }
};

export default CityService;
import React from "react";
import { Button, CircularProgress } from "@material-ui/core";

const CustomButton = props => {

    const { loading, label, ...values } = props;

    return (
        <Button {...values} >
            {
                loading ? <CircularProgress color="secondary" size={25}  /> : props.label
            }
        </Button>
    );
};

export default CustomButton;
export const REGISTER_URL = "register";
export const LOGIN_URL = "login";
export const PASSWORD_URL = "password/";
export const FORGOT_PASSWORD_URL = "forgotPassword"
export const RESET_PASSWORD_URL = "resetPassword"
export const CITY_URL = "cities";
export const USER_URL = "users";
export const USER_EXCEL_URL = "users/excel";
export const USER_REMOVE_ACCOUNT_URL = "users/deleteAccount";
export const USER_REMOVE_MANY_URL = "users/delete";
export const USER_ACTIVATE_ACCOUNT_URL = "users/activate";
export const NOTIFICATION_URL = "notifications";
export const NOTIFICATION_TEMPLATE_URL = "notificationTemplates";
export const WORKSHEET_URL = "worksheets";
export const WORKSHEET_REMOVE_MANY_URL = "worksheets/delete";
export const NOTIFICATION_TABLE_URL = "notifications/notificationTable/";
export const NOTIFICATION_FILTER_URL = "notifications/notificationTable/filter";
export const NOTIFICATION_DOWNLOAD_URL = "notifications/notificationTable/downloadPDF"


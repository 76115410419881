import clsx from 'clsx';
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Add, Delete, CloudDownload } from "@material-ui/icons";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { Grid, Toolbar, Tooltip, Typography, IconButton, Fab } from "@material-ui/core";
import { Button } from "../../../components";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%'
    },
    buttonDownload: {
        width: "200px"
    }
}));

const EnhancedTableToolbar = (props) => {

    const classes = useToolbarStyles();
    const [loading, setLoading] = useState(false);
    const { numSelected, title, addEvent, deleteEvent, exportAction, downloadPDF } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >

            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} Selecionado(s)
                </Typography>
            ) : (
                    <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                        {title}
                    </Typography>
                )
            }

            {(downloadPDF) ?
                <Button
                    className={classes.buttonDownload}
                    variant="contained"
                    color="primary"
                    label={"Download PDF"}
                    loading={loading}
                    onClick={() => downloadPDF(setLoading)}
                /> : false
            }
            
            {numSelected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton
                        aria-label="delete"
                        onClick={deleteEvent}
                    >
                        <Delete />
                    </IconButton>
                </Tooltip>
            ) : (
                    <React.Fragment>
                        {(exportAction) ?
                            <Tooltip title="Excel">
                                <IconButton
                                    aria-label="download"
                                    style={{ marginRight: "10px" }}
                                    onClick={() => exportAction()}
                                >
                                    <CloudDownload fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            : false}
                        {(addEvent) ?
                            <Tooltip title="Incluir Novo">
                                <Fab
                                    color="primary"
                                    aria-label="incluir novo"
                                    size="small"
                                    onClick={() => addEvent()}
                                >
                                    <Add />
                                </Fab>
                            </Tooltip>
                            : false}
                    </React.Fragment>
                )}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default EnhancedTableToolbar;
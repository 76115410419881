import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Grid, CssBaseline, Card, CardHeader, CardContent, Typography, 
    Link } from "@material-ui/core";

import Logo from "../../../src/assets/images/vargem-logo.png";

const style = makeStyles({
    cardTitle: {
        textAlign: "center"
    },
    logo: {
        paddingTop: "35px",
        width: "300px"
    },
    termMargin: {
        margin: "35px 0"
    },
    subTitle: {
        fontSize: "0.8rem",
        fontWeight: 600,
        margin: "20px 0",
    }
});

const Terms = () => {

    const classes = style();
    const history = useHistory();

    const backToLogin = event => {
        event.preventDefault();
        history.push("/login");
    };

    return <Grid container justify="center" className={classes.root}>
                <CssBaseline />
                <Grid container justify="center" item sm={12}>
                    <img src={Logo} alt="Logo" className={classes.logo} />
                </Grid>
                <Grid item xs={10} sm={6}>
                    <Card className={classes.termMargin}>
                        <CardHeader title="Bem Vindo" className={classes.cardTitle} />
                        <CardContent>
                            <Typography variant="body1" align="center">
                                Você acaba de se registrar no portal da prefeitura de Vargem Grande do Sul, para conseguir efetuar o login, acesse seu email para ativar a conta.
                            </Typography>
                            <Typography align="center" className={classes.subTitle}>
                                Caso não receba o email em 15 minutos verifique a caixa de spam!
                            </Typography>
                            <Grid container justify="center" alignItems="center">
                                <ArrowBackIcon color="primary" /> <Link href="#" onClick={backToLogin}>Ir ao login</Link>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
};

export default Terms;
import React from "react";
import { Route, Redirect } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import Notification from "../pages/Notification";
import TownHall from "../pages/TownHall";
import NotificationTable from "../pages/NotificationTable";
import Worksheet from "../pages/Worksheet";
import MyProfile from "../pages/MyProfile";
import Template from "../container/Template";

const AuthRoutes = props => 
    <Template>
        <Route path="/" exact component={Dashboard} />
        {/* <Route path="/" exact component={Notification} /> */}
        <Route path="/prefeitura" exact component={TownHall} />
        <Route path="/meu-perfil" exact component={MyProfile} />
        <Route path="/notificacao" exact component={Notification} />
        <Route path="/planilhas" exact component={Worksheet} />
        <Route path="/notificacao-lista/:id" exact component={NotificationTable} />
        <Redirect to="/" />
    </Template>

export default AuthRoutes;
import React from "react";
import { Route, Redirect } from "react-router-dom";

import Template from "../container/Template";
import Dashboard from "../pages/Dashboard";
import TownHall from "../pages/TownHall";
import Photo360 from "../pages/Photo360";
import MyProfile from "../pages/MyProfile";

const PublicRoutes = props => 
    <Template>
        <Route path="/" exact component={Dashboard} />
        <Route path="/meu-perfil" exact component={MyProfile} />
        <Redirect to="/" />
    </Template>

export default PublicRoutes;
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import { Button, Icon } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function SubmitButton({
  loading,
  success,
  children,
  icon,
  handleClick,
  ...rest
}) {
  const classes = useStyles();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  return (
    <div className={`${classes.root} ${rest.className}`}>
      <div className={`${classes.wrapper} ${rest.className}`}>
        <Button
          {...rest}
          className={`${buttonClassname} ${rest.className}`}
          onClick={handleClick}
        >
          {children}
          {icon && <Icon style={{ marginLeft: 10 }}>{icon}</Icon>}
        </Button>
      </div>
    </div>
  );
}

SubmitButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  success: PropTypes.bool,
  handleClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  props: PropTypes.shape(),
};

SubmitButton.defaultProps = {
  success: false,
  props: {},
  handleClick: () => {},
};

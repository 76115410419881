import { store } from "react-notifications-component";

const showNotification = (title, message, type, duration = 3000) => {
    store.addNotification({
        title,
        message,
        type,
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: duration
        }
    });
};

export default showNotification;
import React from "react";
import { makeStyles } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { Select, FormControl, InputLabel, MenuItem, FormHelperText } from '@material-ui/core';

import { showMessage } from "../../utils/errors";

const useStyles = makeStyles(() => ({
    formControl: {
        width: "100%"
    }
}));

export default props => {

    const classes = useStyles();
    const { options, name, rules, ...values } = props;
    const { control, errors } = useFormContext();

    return (
        <FormControl 
            variant="outlined" 
            required={!props.required ? false : true } 
            className={classes.formControl}
            error={errors[`${name}`] ? true : false}
        >
            <InputLabel id={values.labelId}>{values.label}</InputLabel>
            <Controller
                control={control}
                name={name}
                as={
                    <Select 
                        {...values}
                        inputProps={{
                            onChange: e => {
                                if(props.onChange)
                                    props.onChange(e.target.value);
                            }
                        }}
                    >
                        <MenuItem value="">
                            <em>Selecione...</em>
                        </MenuItem>
                        {
                            options && options.map((item, index) => {
                                return <MenuItem value={item.value} key={index}>{item.label}</MenuItem>
                            })
                        }
                    </Select>
                }
                rules={rules}
                defaultValue=""
            />
            <FormHelperText>{showMessage(rules, name, errors)}</FormHelperText>
        </FormControl>
    );
};
import axios from "axios";
import { getToken } from "../utils/user";
import promisefy from "../utils/promisefy";

const baseURL = process.env.REACT_APP_BASE_URL_API;
const headers = {
    "Content-type": "application/json",
    "Accept": "application/json"
};
const headersFile = {
    "responseType": "blob"
};

export default class HttpRequest {
    constructor(token, url, file = false){
        if(token)
            headers.Authorization = `Bearer ${getToken()}`;
        
        if(!file)
            this.request = axios.create({baseURL, headers});
        else
            this.request = axios.create({baseURL, headersFile});

        this.url = url;
    };

    get(param = null) {
        if(param) this.url = `${this.url}/${param}`;
        return promisefy(this.request.get(this.url));
    };

    search(value) {
        return promisefy(this.request.get(`${this.url}?param=${value}`));
    };

    post(data) {
        return promisefy(this.request.post(this.url, data));
    };

    delete(id) {
        return promisefy(this.request.delete(`${this.url}/${id}`))
    };
};
import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import notification from "../../utils/notification";
import { DataTable, DialogBox, Button } from "../../components";
import AddFileButton from "../../components/DialogBox/addFile";
import WorksheetService from "../../services/WorksheetService";

const styles = makeStyles(theme => ({
    main: {
        [theme.breakpoints.up("sm")]: {
            marginLeft: "58px"
        },
        padding: "30px"
    },
    buttonSpacing: {
        padding: "0px !important",
        textAlign: "right",
        width: "100%"
    }
}));

const columns = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Nome' },
    { id: 'actions', numeric: false, disablePadding: true, label: 'Ações' },
];

const Worksheet = props => {
    const history = useHistory();

    const classes = styles();
    const [dialog, setDialog] = useState(false);
    const [dialogFile, setDialogFile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dimensions, setDimensions] = useState([]);
    const [valuesToDelete, setValuesToDelete] = useState([]);
    const [worksheets, setWorksheets] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        setDimensions({
            height: document.getElementsByClassName("MuiAppBar-root")[0].clientHeight,
            width: window.outerWidth > 600 ? (window.outerWidth - 58) : window.outerWidth
        });
        setDialogFile(false);
        try {
            
            const resp = await new WorksheetService().get();

            if (resp.status === 200)
                setWorksheets(resp.data)

        } catch (err) {

            if (!err.response)
                notification("Ops!", "Falha de conexão", "danger");

        }
    };

    const handleDelete = async values => {
        setDialog(true);
        setValuesToDelete(values);
    };

    const closeDialog = () => {
        setDialog(false);
    };

    const closeDialogFile = () => {
        setDialogFile(false);
    };

    const deleteRows = async values => {
        setLoading(true);
        const { password } = values;

        const data = {
            password,
            valuesToDelete
        };

        try {

            const response = await new WorksheetService().deleteMany(data);

            if (response.status === 200) {
                notification("Sucesso!", "Linhas removidas com sucesso", "success");
                await getData();
                setValuesToDelete([]);
                setDialog(false);
            }
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
            if (!error.response) {
                notification("Ops!", "Falha de conexão", "danger");
                return;
            }

            if (error.response.status === 401) {
                notification("Ops!", "Senha incorreta!", "warning");
                return;
            }
        }
    };

    const openWorksheet = (id) => {
        history.push("/notificacao-lista/" + id)
    }

    return (
        <Grid container className={classes.main} style={{
            height: `calc(100vh - ${(dimensions.height + 4)}px)`,
            width:  dimensions.width
        }}>
            <DataTable
                columns={columns}
                data={worksheets}
                title="Planilhas"
                deleteAction={handleDelete}
                exportAction={false}
                type="workSheet"
                openNotificationTable={(id) => openWorksheet(id)}
            />
            <DialogBox
                open={dialog}
                onClose={closeDialog}
                title="Atenção!"
                description="Para remover as linhas selecionadas, digite sua senha no campo abaixo:"
                closeLabel="Cancelar"
                submitLabel="Confirmar"
                onSubmit={deleteRows}
                loading={loading}
            />
            <AddFileButton
                open={dialogFile}
                onClose={closeDialogFile}
                title="Nova Planilha"
                description="Qual nome deseja dar à planilha:"
                closeLabel="Voltar"
                onSubmit={deleteRows}
                loading={loading}
                getData={getData}
            />
            <div className={classes.buttonSpacing}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ width: "200px" }}
                    label={"Adicionar planilha"}
                    loading={loading}
                    onClick={() => setDialogFile(true)}
                />
            </div>
        </Grid>
    );
};

export default Worksheet;
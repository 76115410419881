import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Controller, useForm } from "react-hook-form";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory, useParams } from "react-router-dom";
import {
    Grid, CssBaseline, Card, CardHeader, CardContent, Typography, Link, 
    TextField, InputAdornment, IconButton
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { Button } from "../../components";

import PasswordService from "../../services/PasswordService";
import notification from "../../utils/notification";

import Logo from "../../../src/assets/images/vargem-logo.png";

const style = makeStyles(theme => ({
    centerAlign: {
        textAlign: "center"
    },
    logo: {
        width: "300px",
        paddingTop: "35px"
    },
    termMargin: {
        margin: "35px 0"
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    margin: {
        marginTop: "15px"
    }
}));

const ResetPassword = props => {

    const classes = style();
    const history = useHistory();
    const { token } = useParams();
    const { errors, control, getValues, handleSubmit } = useForm({
        reValidateMode: "onSubmit"
    });

    const backToLogin = event => {
        event.preventDefault();
        history.push("/login");
    };

    const [loading, setLoading] = useState(false);
    const [inputTypePassword, setInputTypePassword] = useState("password");
    const [inputTypeConfirmPassword, setInputTypeConfirmPassword] = useState("password");

    const changeInputTypePassword = () => {
        if(inputTypePassword === "password")
            setInputTypePassword("text");
        else
            setInputTypePassword("password");
    };

    const changeInputTypeConfirmPassword = () => {
        if(inputTypeConfirmPassword === "password")
            setInputTypeConfirmPassword("text");
        else
            setInputTypeConfirmPassword("password");
    };

    const resetPassword = async values => {

        setLoading(true);

        const { password } = values;

        try {

            const resp = await new PasswordService().resetPassword({
                password,
                token
            });

            if (resp.status !== 200) {
                notification("Ops!", "Houve uma falha", "danger");
                return;
            }

            notification("Sucesso!", "Senha alterada com sucesso", "success");
            history.push("/login");
        } catch (error) {
            if (!error.response) {
                notification("Ops!", "Houve uma falha", "danger");
                return;
            }
        }

        setLoading(false);
    };

    return <Grid container justify="center" className={classes.root}>
            <CssBaseline />
            <Grid container justify="center" item sm={12}>
                <img src={Logo} alt="Logo" className={classes.logo} />
            </Grid>
            <Grid item xs={10} sm={6}>
                <Card className={classes.termMargin}>
                    <CardHeader title="Redefinição de Senha" className={classes.centerAlign} />
                    <CardContent className={classes.centerAlign}>
                        <Typography variant="body1">
                            Digite a nova senha nos campos abaixo.
                        </Typography>
                        <form
                            className={classes.form}
                            onSubmit={handleSubmit(resetPassword)}
                        >
                            <Controller
                                control={control}
                                name="password"
                                as={
                                    <TextField
                                        id="password"
                                        type={inputTypePassword}
                                        name="password"
                                        label="Senha"
                                        fullWidth
                                        variant="outlined"
                                        error={errors["password"] ? true : false}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={changeInputTypePassword}
                                                        edge="end"
                                                    >
                                                        {inputTypePassword === "text" 
                                                            ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>

                                        }}
                                        helperText={errors["password"]?.type === "required" 
                                            && "Digite a senha"}
                                    />
                                }
                                defaultValue=""
                                rules={{
                                    required: true
                                }}
                            />
                            <Controller
                                control={control}
                                name="confirmPassword"
                                as={
                                    <TextField
                                        id="confirmPassword"
                                        type={inputTypeConfirmPassword}
                                        name="confirmPassword"
                                        label="Confirmação de senha"
                                        fullWidth
                                        variant="outlined"
                                        error={errors["confirmPassword"] ? true : false}
                                        className={classes.margin}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={changeInputTypeConfirmPassword}
                                                        edge="end"
                                                    >
                                                        {inputTypeConfirmPassword === "text" 
                                                            ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>

                                        }}
                                        helperText={errors["confirmPassword"]?.type === "required" 
                                            && "Digite a confirmação de senha" || 
                                            errors["confirmPassword"]?.type === "validate" 
                                                && "As senhas devem ser iguais"}
                                    />
                                }
                                defaultValue=""
                                rules={{
                                    required: true,
                                    validate: value => value === getValues("password")
                                }}
                            />
                            <Button
                                type="submit"
                                label="Redefinir Senha"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                loading={loading}
                            />
                        </form>
                        <Grid container justify="center" alignItems="center">
                            <ArrowBackIcon color="primary" /> <Link href="#" onClick={backToLogin}>Ir ao Login</Link>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
};

export default ResetPassword;
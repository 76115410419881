import React from 'react';
import Form from "../Form";
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, 
    Typography, CircularProgress } from '@material-ui/core';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    modalWidth: "600px"
});

const CustomDialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <DialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
});

const CustomDialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(DialogContent);

const CustomDialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    }
}))(DialogActions);

export default function CustomizedDialogs(props) {

    const { 
        open, 
        closeEvent,
        title,
        children,
        submitEvent,
        defaultValues,
        loading
    } = props;

    return (
        <div>
            <Dialog 
                onClose={() => closeEvent()} 
                aria-labelledby="customized-dialog-title" 
                open={open}
                fullWidth={true}
            >
                <Form
                    submit={submitEvent}
                    defaultValues={defaultValues}
                >
                    <CustomDialogTitle 
                        id="customized-dialog-title" 
                        onClose={() => closeEvent()}
                    >
                        {title}
                    </CustomDialogTitle>
                    <CustomDialogContent dividers>
                        {children}
                    </CustomDialogContent>
                    <CustomDialogActions>
                        <Button 
                            type="submit"
                            color="primary"
                            variant="contained"
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : "Salvar"}
                        </Button>
                    </CustomDialogActions>
                </Form>
            </Dialog>
        </div>
    );
};
import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import Preview from './Preview';

import { Close as CloseIcon } from '@material-ui/icons';
import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Tooltip,
  Container,
} from '@material-ui/core';

const PreviewComponent = props => {
  const { content } = props;

  return (
    <Container style={{paddingTop: "80px"}}>
      <Preview content={content} />
    </Container>
  );
};

const PreviewModal = props => {
  const {
    isOpen,
    handleClose,
    content,
    previewTitle,
    PreviewComponent,
  } = props;

  return (
    <Dialog fullScreen maxWidth={false} open={isOpen} onClose={handleClose} style={{height: "100%"}}>
      <div>
        <AppBar>
          <Toolbar>
            <Tooltip title="Fechar preview">
              <IconButton
                color="inherit"
                onClick={handleClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>

            <Typography variant="h6" color="inherit">
              Preview - {previewTitle}
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ height: '100%' }}>
          <PreviewComponent content={content} />
        </div>
      </div>
    </Dialog>
  );
};

PreviewModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  PreviewComponent: PropTypes.any,
};

PreviewModal.defaultProps = {
  PreviewComponent,
};

export default PreviewModal;

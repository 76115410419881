import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Controller, useFormContext } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@material-ui/core";

import { showMessage } from "../../utils/errors";

const styles = makeStyles({
    errorMessage: {
        color: "#ff0000"
    }
});

export default props => {

    const classes = styles();
    const { 
        name, 
        rules, 
        onClick,
        label,
        linklabel,
        ...values 
    } = props;

    const { register, control } = useFormContext();

    return (
        <Controller
            name="Checkbox"
            control={control}
            render={(props) => (
                <Checkbox
                    onChange={(e) => props.onChange(e.target.checked)}
                    checked={props.value}
                />
            )}
        />
    );

};
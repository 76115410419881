import React from "react";
import { Typography, Link } from '@material-ui/core';

export default props => {

    const openLink = event => {
        event.preventDefault();
        window.open("https://satoengenharia.com.br", "_blank");
    };

    return (
        <Typography variant="body2" color="textSecondary" align="center">
            Desenvolvido por{' '}
            <Link href="#" onClick={openLink}>Sato & Malosso.</Link>{' '} © {new Date().getFullYear()}
        </Typography>
    );
};
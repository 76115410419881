import HttpRequest from "./HttpRequest";
import promisefy from "../utils/promisefy";
import { 
    NOTIFICATION_TEMPLATE_URL
} from "../utils/url";

class NotificationTemplateService extends HttpRequest {

    constructor(file) {
        super(true, NOTIFICATION_TEMPLATE_URL, file = false);
    };
};

export default NotificationTemplateService;
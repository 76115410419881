import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    root: {
        height: 0
    },
    input: {
        display: 'none'
    }
}));

export default function UploadButtons(props) {
    const classes = useStyles();
    const {
        setFile
    } = props;

    return (
        <div className={classes.root}>
            <input
                accept=".ods,.xlsx"
                className={classes.input}
                id="file"
                type="file"
                onChange={e => setFile(e.target.files[0])}
            />
            <label htmlFor="file">
                <Button variant="contained" color="primary" component="span">
                    Selecionar arquivo
                </Button>
            </label>
        </div>
    );
}

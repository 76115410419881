import moment from "moment";
import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import notification from "../../utils/notification";
import { makeStyles } from "@material-ui/core/styles";
import { UserService, CityService } from "../../services";
import { isValidCPF, removePonctuation } from "../../utils/utils";
import { DataTableUsers, Input, Select, Modal, DialogBox } from "../../components";

import ufs from "../../data/uf";
import profiles from "../../data/profiles";

const styles = makeStyles(theme => ({
    main: {
        [theme.breakpoints.up("sm")]: {
            marginLeft: "58px"
        },
        padding: "30px"
    }
}));

const columns = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Nome' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'profileId', numeric: false, disablePadding: false, label: 'Perfil' },
    { id: 'active', numeric: false, disablePadding: false, label: 'Ativo' },
    { id: 'edit', numeric: false, disablePadding: false, label: 'Editar' },
];

const Users = props => {

    const classes = styles();
    const [users, setUsers] = useState([]);
    const [cities, setCities] = useState([]);
    const [modal, setModal] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dimensions, setDimensions] = useState([]);
    const [defaultValues, setDefaultValues] = useState(null);
    const [valuesToDelete, setValuesToDelete] = useState([]);

    useEffect(() => {
        init();
        getData();
    }, []);

    const init = async () => {
        setDimensions({
            height: document.getElementsByClassName("MuiAppBar-root")[0].clientHeight,
            width: window.outerWidth > 600 ? (window.outerWidth - 58) : window.outerWidth
        });
    };

    const getData = async () => {
        try {

            const resp = await new UserService().get();

            if (resp.status === 200)
                setUsers(resp.data);

        } catch (err) {

            if (!err.response)
                notification("Ops!", "Falha de conexão", "danger");

        }
    };

    const changeUF = value => getCities(value);

    const getCities = async param => {
        const response = await new CityService().get(param);

        if (response.status !== 200) {
            setCities([]);
            return;
        }

        const resp = response.data.object.map(item => {
            return {
                value: item.id,
                label: item.name
            };
        })
        setCities(resp);
    };

    const closeModal = () => setModal(false);

    const handleAdd = () => {

        const user = {
            secureId: "",
            name: "",
            cpf: "",
            email: "",
            password: "",
            telephone: "",
            cellphone: "",
            state: "",
            cityId: "",
            street: "",
            number: "",
            neighborhood: "",
            zipcode: "",
            active: 1
        };

        setDefaultValues(user);
        setModal(true);
    };

    const handleEdit = async id => {
        await getDataById(id);
        setModal(true);
    };

    const handleDelete = async values => {
        setDialog(true);
        setValuesToDelete(values)
    };

    const getDataById = async id => {

        const resp = await new UserService().get(id);

        if (resp.status === 200) {

            const { ...object } = resp.data.object[0];

            const user = {
                secureId: object.secureId,
                name: object.name,
                cpf: object.cpf,
                email: object.email,
                password: object.password,
                telephone: object.telephone,
                cellphone: object.cellphone,
                state: object.city.state,
                cityId: object.cityId,
                street: object.street,
                number: object.number,
                neighborhood: object.neighborhood,
                zipcode: object.zipcode,
                active: object.active == 0 ? 2 : object.active,
                profileId: object.profileId
            };

            await getCities(user.state);
            setDefaultValues(user);
        }
    };

    const handleSubmit = async values => {

        if (values.secureId == "" && values.password == "") {
            notification("Atenção!", "Digite uma senha", "warning");
            return;
        }

        setLoading(true);

        const { ...data } = values;

        data.cpf = removePonctuation(values.cpf);
        data.telephone = removePonctuation(values.telephone);
        data.cellphone = removePonctuation(values.cellphone);
        data.password = values.password.trim();
        data.number = values.number.trim();
        data.zipcode = removePonctuation(values.zipcode);
        data.url = `${window.location.origin}/ativacao/`;

        const resp = await new UserService().post(data);

        if (resp.status === 200) {
            await getData();
            notification(resp.data.title, resp.data.message, resp.data.type);

            if(resp.data.status) setModal(false);
        }
        
        setLoading(false);
    };

    const closeDialog = () => {
        setDialog(false);
    };

    const deleteRows = async values => {
        const { password } = values;

        const data = {
            password,
            valuesToDelete
        };

        try {

            const response = await new UserService().deleteMany(data);

            if (response.status === 200) {
                notification("Sucesso!", "Linhas removidas com sucesso", "success");
                await getData();
                setValuesToDelete([]);
                setDialog(false);
            }

        } catch (error) {
            if (!error.response) {
                notification("Ops!", "Falha de conexão", "danger");
                return;
            }

            if (error.response.status === 401) {
                notification("Ops!", "Senha incorreta!", "warning");
                return;
            }
        }
    };

    const exportExcel = async () => {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', `${process.env.REACT_APP_BASE_URL_API}users/excel`, true);
        xhr.responseType = 'arraybuffer';
        xhr.onload = function () {
            if (this.status == 200) {
                var filename = `usuarios-${moment().format("YYYYMMDD-HHmmss")}.xlsx`;
                var disposition = xhr.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1])
                        filename = matches[1].replace(/['"]/g, '');
                }
                var type = xhr.getResponseHeader('Content-Type');

                var blob = typeof File === 'function'
                    ? new File([this.response], filename, { type })
                    : new Blob([this.response], { type });
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var URL = window.URL || window.webkitURL;
                    var downloadUrl = URL.createObjectURL(blob);

                    if (filename) {
                        // use HTML5 a[download] attribute to specify filename
                        var a = document.createElement("a");
                        // safari doesn't support this yet
                        if (typeof a.download === 'undefined') {
                            window.location = downloadUrl;
                        } else {
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                        }
                    } else {
                        window.location = downloadUrl;
                    }
                    setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
                }
            };
        };
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.send();
    };

    return (
        <Grid container className={classes.main} style={{
            height: `calc(100vh - ${(dimensions.height + 4)}px)`,
            width: dimensions.width
        }}>
            <Grid item xs={12}>
                <DataTableUsers
                    columns={columns}
                    data={users}
                    title="Usuários"
                    addAction={handleAdd}
                    editAction={handleEdit}
                    deleteAction={handleDelete}
                    exportAction={exportExcel}
                    type="user"
                />
            </Grid>
            <Modal
                open={modal}
                title="Editar"
                closeEvent={closeModal}
                submitEvent={handleSubmit}
                defaultValues={defaultValues}
                loading={loading}
            >
                <Grid container spacing={3}>
                    <Input
                        name="secureId"
                        type="hidden"
                    />
                    <Grid item xs={12} sm={6}>
                        <Input
                            autoFocus
                            id="name"
                            name="name"
                            label="Nome"
                            type="text"
                            variant="outlined"
                            fullWidth
                            required
                            rules={{
                                required: true,
                                minLength: 3,
                                maxLength: 50
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="cpf"
                            name="cpf"
                            label="CPF"
                            type="mask"
                            variant="outlined"
                            fullWidth
                            required
                            mask="999.999.999-99"
                            rules={{
                                validate: value => isValidCPF(value) === true,
                                validateType: "CPF"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            id="email"
                            name="email"
                            label="Email"
                            type="text"
                            fullWidth
                            required
                            variant="outlined"
                            rules={{
                                required: true,
                                pattern: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
                                validateType: "Email"
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            id="password"
                            type="password"
                            name="password"
                            label="Senha"
                            fullWidth
                            required
                            variant="outlined"
                            rules={{
                                minLength: 8,
                                maxLength: 14
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="telephone"
                            name="telephone"
                            label="Telefone"
                            type="mask"
                            variant="outlined"
                            fullWidth
                            mask="(99) 9999-9999"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="cellphone"
                            name="cellphone"
                            label="Celular"
                            type="mask"
                            required
                            variant="outlined"
                            fullWidth
                            mask="(99) 99999-9999"
                            rules={{
                                required: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select
                            id="state"
                            name="state"
                            labelId="state-label"
                            label="UF"
                            options={ufs}
                            onChange={changeUF}
                            required
                            rules={{
                                required: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select
                            id="cityId"
                            name="cityId"
                            labelId="cityId-label"
                            label="Cidade"
                            required
                            rules={{}}
                            options={cities}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            id="street"
                            name="street"
                            label="Logradouro"
                            type="text"
                            variant="outlined"
                            required
                            fullWidth
                            inputProps={{
                                maxLength: 100
                            }}
                            rules={{
                                required: true,
                                minLength: 3,
                                maxLength: 100
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="number"
                            name="number"
                            label="Número"
                            type="mask"
                            variant="outlined"
                            fullWidth
                            mask="9999"
                            hidemask
                            maskChar={' '}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="neighborhood"
                            name="neighborhood"
                            label="Bairro"
                            type="text"
                            variant="outlined"
                            fullWidth
                            required
                            inputProps={{
                                maxLength: 100
                            }}
                            rules={{
                                required: true,
                                minLength: 3,
                                maxLength: 100
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            id="zipcode"
                            name="zipcode"
                            label="CEP"
                            type="mask"
                            variant="outlined"
                            fullWidth
                            required
                            mask="99999-999"
                            rules={{
                                required: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select
                            id="profileId"
                            name="profileId"
                            labelId="profile-id-label"
                            label="Perfil"
                            options={profiles}
                            required
                            rules={{
                                required: true
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Select
                            id="active"
                            name="active"
                            labelId="active-label"
                            label="Ativo"
                            options={[
                                {
                                    label: "Sim",
                                    value: 1
                                },
                                {
                                    label: "Não",
                                    value: 2
                                }
                            ]}
                        />
                    </Grid>
                </Grid>
            </Modal>
            <DialogBox
                open={dialog}
                onClose={closeDialog}
                title="Atenção!"
                description="Para remover as linhas selecionadas, digite sua senha no campo abaixo:"
                closeLabel="Cancelar"
                submitLabel="Confirmar"
                onSubmit={deleteRows}
            />
        </Grid>
    );
};

export default Users;
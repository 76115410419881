import React, { useState } from 'react';
import {
    Button, Dialog, TextField, DialogTitle, DialogActions,
    DialogContent, DialogContentText, Typography, CircularProgress
} from '@material-ui/core';
import UploadButton from "../UploadButton/index";
import { getToken } from "../../utils/user";
import notification from "../../utils/notification";


const axios = require('axios');

const baseURL = process.env.REACT_APP_BASE_URL_API;

const api = axios.create({
    baseURL,
    timeout: 60000,
});

const AddFileButton = props => {
    const [fileName, setFileName] = useState("");
    const [file, setFile] = useState('');
    const [loading, setLoading] = useState(false);

    const saveFiles = async () => {
        setLoading(true);
        if (file === '') {
            setLoading(false);
            notification('Ops!', "Adicione um arquivo", "warning")
            return 0;
        }
        const formData = new FormData();
        formData.append('file', file);
        try {
            const response = await api.post('/notifications', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${getToken()}`
                }
            });
            const { worksheetId } = response.data;
            try {
                await api.put('/notifications', { worksheetId, fileName }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getToken()}`
                    }
                });
                await props.getData();
                setFileName('');
                setFile('');
                props.onClose(false)
                notification('Sucesso', "Planilha adicionada", "success")
            } catch (err) {
                setLoading(false);
                props.onClose(false)
                console.log(err)
                notification('Ops!', "Perda de conexão", "warning")
            }
        } catch (err) {
            setLoading(false);
            console.log(err)
            notification('Ops!', "Perda de conexão", "warning")
        }
        setLoading(false);
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title" style={{ textAlign: "center" }}>{props.title}</DialogTitle>
            <DialogContent style={{
                height: "200px", width: "500px",
                alignContent: "center", alignItems: "center",
                textAlign: "center"
            }}>
                <DialogContentText>{props.description}</DialogContentText>
                <TextField
                    label="Nome"
                    style={{ width: "250px", paddingBottom: "20px" }}
                    value={fileName}
                    onChange={(event) => setFileName(event.target.value)}
                    variant="outlined"
                />
                <UploadButton
                    setFile={setFile}
                />
                <div style={{paddingTop: "60px"}}>
                    <Typography variant="body2">
                        {(file !== '') ?
                            file.name
                            :false
                        }
                    </Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose} color="primary">
                    {props.closeLabel}
                </Button>
                <Button onClick={() => saveFiles()} color="primary" variant="contained">
                    {(!loading) ? "Salvar" : <CircularProgress color="secondary" />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddFileButton;
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, CssBaseline, Card, CardHeader, CardContent, Typography } from "@material-ui/core";

import Logo from "../../../src/assets/images/vargem-logo.png";

const style = makeStyles({
    cardTitle: {
        textAlign: "center"
    },
    logo: {
        paddingTop: "35px",
        width: "300px"
    },
    termMargin: {
        margin: "35px 0"
    }
});

export default props => {

    const classes = style();

    return <Grid container justify="center" className={classes.root}>
            <CssBaseline />
            <Grid container justify="center" item sm={12}>
                <img src={Logo} alt="Logo" className={classes.logo} />
            </Grid>
            <Grid item xs={10} sm={6}>
                <Card className={classes.termMargin}>
                    <CardHeader title="404" className={classes.cardTitle} />
                    <CardContent>
                        <Typography variant="body1" className={classes.cardTitle}>
                            Ops!... Página não encontrada
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
};
import HttpRequest from "./HttpRequest";
import { USER_ACTIVATE_ACCOUNT_URL } from "../utils/url";

class ActivationService extends HttpRequest {

    constructor() {
        super(null, USER_ACTIVATE_ACCOUNT_URL);
    }
};

export default ActivationService;
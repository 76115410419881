import HttpRequest from "./HttpRequest";
import promisefy from "../utils/promisefy";
import { PASSWORD_URL, FORGOT_PASSWORD_URL, RESET_PASSWORD_URL } from "../utils/url";

class PasswordService extends HttpRequest {

    constructor() {
        super(null, PASSWORD_URL);
    };

    forgotPassword(data) {
        return promisefy(this.request.post(FORGOT_PASSWORD_URL, data));
    };

    resetPassword(data) {
        return promisefy(this.request.post(RESET_PASSWORD_URL, data));
    };

};

export default PasswordService;
import React, { useEffect, useState } from "react";

import { PrivateTab } from "../../components";

const TownHall = props => {

    const [privateTab, setPrivateTab] = useState(false);
    const [dimensions, setDimension] = useState({
        height: 0,
        width: 0,
        paddingLeft: 0
    });

    const init = async () => {
        const isPrivate = await detectPrivateChrome();
        const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

        if(isPrivate){
            setPrivateTab(isPrivate);
            return;
        }

        let width = window.outerWidth > 600 ? (window.outerWidth - 58) : window.outerWidth;
        if(isFirefox)
            width -= 16;

        setDimension({
            height: document.getElementsByClassName("MuiAppBar-root")[0]?.clientHeight,
            width,
            paddingLeft: window.outerWidth > 600 ? 58 : 0
        });
    };

    const detectPrivateChrome = async () => {
        let isPrivate;

        if ("storage" in navigator && "estimate" in navigator.storage) {
            const { quota } = await navigator.storage.estimate();
            if (quota < 600000000)
                isPrivate = true;
            else
                isPrivate = false;
        } else {
            isPrivate = null;
        }

        return isPrivate;
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <React.Fragment>
            {
                privateTab ? <PrivateTab /> : <iframe 
                    src="https://geovgsul.maps.arcgis.com/apps/webappviewer/index.html?id=4899b8e437c9452aaef5f0292a5045de"
                    frameBorder="0"
                    style={{
                        height: `calc(100vh - ${(dimensions.height+4)}px)`,
                        width: dimensions.width,
                        paddingLeft: dimensions.paddingLeft
                    }}
                />
            }
        </React.Fragment>
    );
};

export default TownHall;
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Grid, CssBaseline, Card, CardHeader, CardContent, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Form, Input, Copyright, Button } from "../../components";
import PasswordService from "../../services/PasswordService";
import notification from "../../utils/notification";

import Logo from "../../../src/assets/images/vargem-logo.png";

const style = makeStyles(theme => ({
    centerAlign: {
        textAlign: "center"
    },
    termMargin: {
        margin: "35px 0"
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    logo: {
        width: "300px",
        padding: "45px 0px 25px 0px"
    },
    copyright: {
        position: "absolute",
        bottom: 10,
        right: 10
    }
}));

const RequestResetPassword = props => {

    const classes = style();
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const backToLogin = event => {
        event.preventDefault();
        history.push("/login");
    };

    const submitForm = async values => {

        setLoading(true);

        const { email } = values;
        const url = `${window.location.origin}/redefinir-senha/`;

        const data = {
            email,
            url
        };

        try {
            
            const resp = await new PasswordService().forgotPassword(data);

            if(resp.status !== 200){
                notification("Ops!", "Houve uma falha", "danger");
                return;
            }

            notification("Sucesso!", "Link de recuperação de senha enviado com sucesso", "success");
        } catch(error) {
            if(!error.response){
                notification("Ops!", "Houve uma falha", "danger");
                return;
            }
        }

        setLoading(false);
    };

    return <Grid container justify="center" className={classes.root}>
            <CssBaseline />
            <Grid container justify="center" item sm={12}>
                <img src={Logo} alt="Logo" className={classes.logo} />
            </Grid>
            <Grid item xs={10} sm={6}>
                <Card className={classes.termMargin}>
                    <CardHeader title="Recuperar Senha" className={classes.centerAlign} />
                    <CardContent className={classes.centerAlign}>
                        <Typography variant="body1">
                            Digite seu e-mail no campo abaixo, para enviarmos um email com as instruções para alterar a senha.
                        </Typography>
                        <Form
                            className={classes.form}
                            submit={submitForm}
                        >
                            <Input
                                autoFocus
                                id="email"
                                name="email"
                                label="Email"
                                type="text"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                rules={{
                                    required: true
                                }}
                            />
                            <Button
                                type="submit"
                                label="Enviar Email"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                loading={loading}
                            />
                        </Form>
                        <Grid container justify="center" alignItems="center">
                            <ArrowBackIcon color="primary" /> <Link href="#" onClick={backToLogin}>Voltar ao login</Link>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <div className={classes.copyright}>
                <Copyright />
            </div>
        </Grid>

};

export default RequestResetPassword;
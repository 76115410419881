import React from "react";
import { useHistory } from "react-router-dom";
import { clearStorage } from "../../utils/user";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { AccountCircle, ExitToApp, Home, People, Map, Notifications, Assignment } from "@material-ui/icons";
import { getUserData } from "../../utils/user";

const styles = makeStyles(theme => ({
    sidebar: {
        width: "58px",
        height: "100vh",
        backgroundColor: "#18ad5a",
        [theme.breakpoints.down("xs")]: {
            display: "none"
        },
        position: "fixed"
    },
    styleButton: {
        margin: "10px",
        color: "#fff"
    }
}));

export default props => {

    const classes = styles();
    const history = useHistory();
    const userData = getUserData();

    const goTo = route => history.push(route);

    const singOut = () => {
        clearStorage();
        history.push("/login");
    };

    return(
        <Grid container direction="column" className={classes.sidebar}>
            <Tooltip title={userData.profileId === 1 ? "Mapa Público" : "Mapa"} placement="right">
                    <IconButton onClick={() => goTo("/")} size="small" className={classes.styleButton}>
                        <Home />
                    </IconButton>
            </Tooltip>
            {
                ((userData.profileId === 1) || (userData.profileId === 4)) && <Tooltip title="Mapa Prefeitura" placement="right">
                    <IconButton onClick={() => goTo("/prefeitura")} size="small" className={classes.styleButton}>
                        <Map />
                    </IconButton>
                </Tooltip>
            }
            {
                userData.profileId === 1 && <Tooltip title="Usuários" placement="right">
                    <IconButton onClick={() => goTo("/usuarios")} size="small" className={classes.styleButton}>
                        <People />
                    </IconButton>
                </Tooltip>
            }
            {
                userData.profileId === 4 && <Tooltip title="Notificações" placement="right">
                    <IconButton onClick={() => goTo("/notificacao")} size="small" className={classes.styleButton}>
                        <Notifications />
                    </IconButton>
                </Tooltip>
            }
            {
                userData.profileId === 4 && <Tooltip title="Planilhas" placement="right">
                    <IconButton onClick={() => goTo("/planilhas")} size="small" className={classes.styleButton}>
                        <Assignment />
                    </IconButton>
                </Tooltip>
            }
            <Tooltip title="Meu Perfil" placement="right">
                <IconButton onClick={() => goTo("/meu-perfil")} size="small" className={classes.styleButton}>
                    <AccountCircle />
                </IconButton>
            </Tooltip>
            <Tooltip title="Sair" placement="right">
                <IconButton onClick={() => singOut()} size="small" className={classes.styleButton}>
                    <ExitToApp />
                </IconButton>
            </Tooltip> 
        </Grid>
    );
};
import moment from "moment";
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CloudDownload, Edit, Visibility } from '@material-ui/icons';
import {
    Table, TableBody, TableCell, TableContainer, TablePagination,
    TableRow, Paper, Checkbox, IconButton, Tooltip
} from '@material-ui/core';

import Modal from "../Modal";
import EnhancedTableHead from "./Head";
import EnhancedTableToolbar from "./Toolbar";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    }
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) return -1;

    if (b[orderBy] > a[orderBy]) return 1;

    return 0;
};

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
};

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

export default function EnhancedTable(props) {

    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [dense, setDense] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        setRows(props.data);
        setOrderBy(props.columns[0].id)
    }, [props.data, props.columns]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.secureId ? n.secureId : n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, secureId) => {
        const selectedIndex = selected.indexOf(secureId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, secureId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDelete = () => {
        props.deleteAction(selected);
        setSelected([]);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    title={props.title}
                    addEvent={props.addAction}
                    deleteEvent={handleDelete}
                    downloadPDF={props.downloadPDF}
                />
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={props.columns}
                        />
                        <TableBody>
                            {stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.secureId ? row.secureId : row.id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.secureId ? row.secureId : row.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox" align="center">
                                                <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    onClick={(event) => handleClick(event, row.secureId ? row.secureId : row.id)}
                                                    align="center"
                                                />
                                            </TableCell>
                                            {
                                                (props.type === "users") ? (
                                                    <>
                                                        <TableCell
                                                            align="center"
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell align="center">{row.email}</TableCell>
                                                        <TableCell align="center">{row.profileId == 2 ? "Prefeitura" : "Público"}</TableCell>
                                                        <TableCell align="center">{row.active ? "Sim" : "Não"}</TableCell>
                                                        <TableCell align="center">
                                                            <IconButton
                                                                color="secondary"
                                                                aria-label="edit data"
                                                                onClick={() => props.editAction(row.secureId ? row.secureId : row.id)}
                                                            >
                                                                <Edit />
                                                            </IconButton>
                                                        </TableCell>
                                                    </>
                                                ) : (props.type === "workSheet") ? (
                                                    <>

                                                        <TableCell align="center"
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            {row.name}
                                                        </TableCell>

                                                        <TableCell align="center"
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            <Tooltip title="Excel">
                                                                <IconButton
                                                                    aria-label="download"
                                                                    style={{ marginRight: "10px" }}
                                                                    onClick={() => props.openNotificationTable(row.secureId ? row.secureId : row.id)}
                                                                >
                                                                    <Visibility />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>

                                                    </>
                                                ) : (
                                                            <>
                                                                <TableCell align="center"
                                                                    component="th"
                                                                    id={labelId}
                                                                    scope="row"
                                                                    padding="none"
                                                                >
                                                                    {row.id}
                                                                </TableCell>
                                                                <TableCell align="center">{row.codImovel}</TableCell>
                                                                <TableCell align="center">{row.owner}</TableCell>
                                                                <TableCell align="center">{row.bciCompleto}</TableCell>
                                                                <TableCell align="center">{row.areaTotalEdificada}</TableCell>
                                                                <TableCell align="center">{row.address}</TableCell>
                                                                <TableCell align="center">{row.downloadDate}</TableCell>
                                                                <TableCell align="center">{row.downloadUser}</TableCell>
                                                                {/* <TableCell align="center">
                                                                <IconButton
                                                                    color="secondary"
                                                                    aria-label="edit data"
                                                                    onClick={() => props.editAction(row.secureId ? row.secureId : row.id)}
                                                                >
                                                                    <Edit />
                                                                </IconButton>
                                                            </TableCell> */}
                                                            </>
                                                        )
                                            }
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    labelRowsPerPage="Linhas por Página"
                    labelDisplayedRows={({ from, to, count }) => {
                        return `${from}-${to} de ${count !== -1 ? count : ("Mais de " + to)}`
                    }}
                />
            </Paper>
        </div>
    );
};
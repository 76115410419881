import HttpRequest from "./HttpRequest";
import promisefy from "../utils/promisefy";
import { 
    USER_URL, 
    USER_REMOVE_ACCOUNT_URL, 
    USER_REMOVE_MANY_URL,
    USER_EXCEL_URL
} from "../utils/url";

class UserService extends HttpRequest {

    constructor(file) {
        super(true, USER_URL, file = false);
    };

    deleteAccount(data) {
        return promisefy(this.request.post(USER_REMOVE_ACCOUNT_URL, data));
    };

    deleteMany(data) {
        return promisefy(this.request.post(USER_REMOVE_MANY_URL, data));
    };

    downloadExcel() {
        return promisefy(this.request.get(USER_EXCEL_URL));
    };
};

export default UserService;
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { Grid, CssBaseline, Card, CardHeader, CardContent, Typography, 
    CircularProgress } from "@material-ui/core";
import { ActivationService } from "../../services";
import notification from "../../utils/notification";

import Logo from "../../../src/assets/images/vargem-logo.png";

const style = makeStyles({
    cardTitle: {
        textAlign: "center"
    },
    logo: {
        paddingTop: "35px",
        width: "300px"
    },
    termMargin: {
        margin: "35px 0"
    },
    subTitle: {
        fontSize: "0.8rem",
        fontWeight: 600,
        margin: "20px 0",
    },
    center: {
        textAlign: "center"
    }
});

const Terms = () => {

    const classes = style();
    const history = useHistory();
    const { token } = useParams();

    useEffect(() => {
        init()
    }, []);

    const init = async () => {
        if(!token){
            history.push("/login");
            return;
        }

        try {
            
            const resp = await new ActivationService().get(token);
            notification(resp.data.title, resp.data.message, resp.data.type);
            history.push("/login");

        } catch(err) {

            if(!err.response){
                notification("Falha", "Tente novamente mais tarde", "danger");
                return;
            }

            notification(err.response.data.title, err.response.data.message, 
                err.response.data.type);
        }

    };

    const backToLogin = event => {
        event.preventDefault();
        history.push("/login");
    };

    return <Grid container justify="center" className={classes.root}>
                <CssBaseline />
                <Grid container justify="center" item sm={12}>
                    <img src={Logo} alt="Logo" className={classes.logo} />
                </Grid>
                <Grid item xs={10} sm={6}>
                    <Card className={classes.termMargin}>
                        <CardHeader title="Ativação" className={classes.cardTitle} />
                        <CardContent className={classes.center}>
                            <Typography variant="body1" align="center">
                                Estamos ativando a sua conta. Por favor aguarde!
                            </Typography>
                            <CircularProgress color="secondary" style={{ marginTop: "20px" }} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
};

export default Terms;
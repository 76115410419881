export default [
    {
        customDropdown: {
            title: 'Dados pessoais',
            options: [
                {
                    key: "id",
                    value: '{{Numero da notificação}}',
                    text: 'Numero da notificação',
                },
                {
                    key: "date",
                    value: '{{Data}}',
                    text: 'Data',
                },
                {
                    key: "city",
                    value: '{{Cidade}}',
                    text: 'Cidade',
                },
                {
                    key: "codImovel",
                    value: '{{CODIGO DO IMOVEL}}',
                    text: 'CODIGO DO IMOVEL',
                },
                {
                    key: "bciCompleto",
                    value: '{{BCI COMPLETO}}',
                    text: 'BCI COMPLETO',
                },
                {
                    key: "areaTotalEdificada",
                    value: '{{AREA TOTAL EDIFICADA}}',
                    text: 'AREA TOTAL EDIFICADA',
                },
                {
                    key: "areaFotoInterpretada",
                    value: '{{AREA FOTOINTERPRETADA}}',
                    text: 'AREA FOTOINTERPRETADA',
                },
                {
                    key: "owner",
                    value: '{{PROPRIETARIO}}',
                    text: 'PROPRIETARIO',
                },
                {
                    key: "deliveryType",
                    value: '{{ENTREGA - TIPO DE LOGRADOURO}}',
                    text: 'ENTREGA - TIPO DE LOGRADOURO',
                },
                {
                    key: "deliveryStreet",
                    value: '{{ENTREGA - NOME DO LOGRADOURO}}',
                    text: 'ENTREGA - NOME DO LOGRADOURO',
                },
                {
                    key: "deliveryNumber",
                    value: '{{ENTREGA - NUMERO}}',
                    text: 'ENTREGA - NUMERO',
                },
                {
                    key: "deliveryDistrict",
                    value: '{{ENTREGA - BAIRRO}}',
                    text: 'ENTREGA - BAIRRO',
                },
                {
                    key: "deliveryCity",
                    value: '{{ENTREGA - CIDADE}}',
                    text: 'ENTREGA - CIDADE',
                },
                {
                    key: "deliveryState",
                    value: '{{ENTREGA - UF}}',
                    text: 'ENTREGA - UF',
                },
                {
                    key: "deliveryCep",
                    value: '{{ENTREGA - CEP}}',
                    text: 'ENTREGA - CEP',
                },
                {
                    key: "addressType",
                    value: '{{TIPO DE LOGRADOURO}}',
                    text: 'TIPO DE LOGRADOURO',
                },
                {
                    key: "addressStreet",
                    value: '{{NOME DO LOGRADOURO}}',
                    text: 'NOME DO LOGRADOURO',
                },
                {
                    key: "addressNumber",
                    value: '{{NÚMERO}}',
                    text: 'NÚMERO',
                },
                {
                    key: "addressDistrict",
                    value: '{{BAIRRO}}',
                    text: 'BAIRRO',
                },
                {
                    key: "bci1",
                    value: '{{BCI-CAMPO 1}}',
                    text: 'BCI-CAMPO 1',
                },
                {
                    key: "bci2",
                    value: '{{BCI-CAMPO 2}}',
                    text: 'BCI-CAMPO 2',
                },
                {
                    key: "bci3",
                    value: '{{BCI-CAMPO 3}}',
                    text: 'BCI-CAMPO 3',
                },
                {
                    key: "bci4",
                    value: '{{BCI-CAMPO 4}}',
                    text: 'BCI-CAMPO 4',
                },
                {
                    key: "bci5",
                    value: '{{BCI-CAMPO 5}}',
                    text: 'BCI-CAMPO 5',
                },
                {
                    key: "immobileSituation",
                    value: '{{SITUAÇÃO DO IMOVEL}}',
                    text: 'SITUAÇÃO DO IMOVEL',
                },
                {
                    key: "unity",
                    value: '{{UNIDADE}}',
                    text: 'UNIDADE',
                },
                {
                    key: "groundArea",
                    value: '{{AREA DO TERRENO}}',
                    text: 'AREA DO TERRENO',
                },
                {
                    key: "groundFraction",
                    value: '{{FRAÇÃO DO TERRENO}}',
                    text: 'FRAÇÃO DO TERRENO',
                },
                {
                    key: "areaEdificada",
                    value: '{{AREA EDIFICADA}}',
                    text: 'AREA EDIFICADA',
                },
                {
                    key: "difference",
                    value: '{{DIFERENÇA (M2)}}',
                    text: 'DIFERENÇA (M2)',
                },
                {
                    key: "situation",
                    value: '{{SITUAÇÃO}}',
                    text: 'SITUAÇÃO',
                }
            ],
        },
    }
]
export default method => {
    return new Promise((resolve, reject) => {
        method.then(resp => {
            const {status, data} = resp;
            resolve({
                status,
                data
            });
        }).catch(error => {
            reject(error);
        });
    });
};
import InputMask from "react-input-mask";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { TextField, InputAdornment, IconButton } from '@material-ui/core';

import { showMessage } from "../../utils/errors";

const Input = props => {

    const { type, name, rules, hidemask, maskChar, ...values } = props;
    const { control, errors, register } = useFormContext();

    const [inputType, setInputType] = useState("password");

    const changeInputType = type => {
        if(inputType === "password")
            setInputType("text")
        else
            setInputType("password")
    };

    if(type === "hidden")
        return <input 
            type={type} 
            name={name} 
            ref={register} 
        />

    if(type === "text")
        return <Controller
            control={control}
            name={name}
            as={<TextField  
                {...values}
                error={errors[`${name}`] ? true : false}
                helperText={showMessage(rules, name, errors)}
            />}
            rules={rules}
            defaultValue=""
        />

    if(type === "mask")
        return <Controller 
            control={control}
            name={name}
            as={
                <InputMask 
                    mask={props.mask} 
                    maskChar={props.maskChar ? props.maskChar : '_'} 
                    alwaysShowMask={hidemask ? false : true}> 
                    {(inputProps) => <TextField  
                        {...values}
                        error={errors[`${name}`] ? true : false}
                        helperText={showMessage(rules, name, errors)}
                    />}
                </InputMask>
            }
            rules={rules}
            defaultValue=""
        />
    
    if(type === "password")
        return <Controller 
                control={control}
                name={name}
                as={
                    <TextField 
                        {...values}
                        type={inputType}
                        error={errors[`${name}`] ? true : false}
                        helperText={showMessage(rules, name, errors)}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={changeInputType}
                                        edge="end"
                                    >
                                        {inputType === "text" ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            
                        }}
                        
                    />
                }
                defaultValue=""
                rules={rules}
            />

};

export default Input;
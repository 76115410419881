export default [
    {
        label: "Prefeitura",
        value: 2
    },
    {
        label: "Público",
        value: 3
    },
    {
        label: "Notificação",
        value: 4
    }
];
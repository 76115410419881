import HttpRequest from "./HttpRequest";
import promisefy from "../utils/promisefy";
import { 
    NOTIFICATION_URL,
    NOTIFICATION_TABLE_URL,
    NOTIFICATION_FILTER_URL,
    NOTIFICATION_DOWNLOAD_URL
} from "../utils/url";

class NotificationService extends HttpRequest {

    constructor(file) {
        super(true, NOTIFICATION_URL, file = false);
    };

    convertXLSX(data) {
        return promisefy(this.request.post(NOTIFICATION_URL, data));
    };

    notificationTable(params) {
        return promisefy(this.request.get((NOTIFICATION_TABLE_URL + params)));
    };

    filterNotification(data) {
        return promisefy(this.request.post(NOTIFICATION_FILTER_URL, data));
    };

    downloadNotifications(data) {
        return promisefy(this.request.post(NOTIFICATION_DOWNLOAD_URL, data));
    };
};

export default NotificationService;
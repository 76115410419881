import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EditorState, Modifier } from 'draft-js';

import './style.css';

class CustomDropdown extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
    title: PropTypes.string,
    options: PropTypes.array,
  };

  state = {
    open: false,
    listItem: []
  };
  
  componentWillMount() {
    this.state.listItem = this.props.options.map(item => (
      <li
        onClick={this.addPlaceholder.bind(this, item.value)}
        key={item.key}
        className="rdw-dropdownoption-default itemDropDown"
      >
        {item.text}
      </li>
    ));
  }

  openPlaceholderDropdown = () => this.setState({ open: !this.state.open });

  addPlaceholder = placeholder => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      placeholder,
      editorState.getCurrentInlineStyle()
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };


  render() {
    return (
      <div
        onClick={this.openPlaceholderDropdown}
        className="rdw-block-wrapper"
        aria-label="rdw-block-control"
      >
        <div
          className="rdw-dropdown-wrapper min-width"
          aria-label="rdw-dropdown"
        >
        <div className="rdw-dropdown-selectedtext" title="Placeholders">
          <span>{this.props.title}</span>
          <div
            className={`rdw-dropdown-caretto${this.state.open ? 'close' : 'open'
              }`}
          />
        </div>
        <ul
          className={`rdw-dropdown-optionwrapper ${this.state.open ? 'showDropDown' : 'hiddenDropDown'
            }`}
        >
          {this.state.listItem}
        </ul>
        </div>
      </div>
    );
  }
}

export default CustomDropdown;

const styles = theme => ({
  textEditor: {
    background: theme.palette.background.paper,
    minHeight: 200,
    border: `1px solid ${theme.palette.divider}`,
    padding: '0 10px',
    color: theme.palette.text.primary,
  },
  toolbarEditor: {
    background: theme.palette.background.default,
    border: 'none',
    '& > div': {
      background: theme.palette.background.paper,
      '& img': {
        filter: theme.palette.type === 'dark' ? 'invert(100%)' : 'invert(0%)',
      },
      '& a': {
        color: theme.palette.text.primary,
        '& > div': {
          borderTopColor: theme.palette.text.primary,
        },
      },
    },
  },
  textPreview: {
    width: '100%',
    resize: 'none',
    height: 305,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(0.5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',

    '@media(min-width: 800px)': {
      marginRight: 12,
    },

    '@media(max-width: 799px)': {
      width: '100%',
      flexDirection: 'column-reverse',
    },
  },
  previewButton: {
    '@media(min-width: 800px)': {
      marginRight: 10,
    },
  },
  saveButton: {
    '@media(max-width: 799px)': {
      width: '100%',
      marginBottom: 5,
    },
  },
});

export default styles;

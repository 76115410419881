import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Link, Paper, CssBaseline } from '@material-ui/core';

import { Form, Input, CheckBox, Copyright, Button } from "../../components";

import SingInService from "../../services/SignInService";

import Image from "../../assets/images/city.jpg";
import Logo from "../../assets/images/vargem-logo.png";

import notification from "../../utils/notification";
import { isLogged } from "../../utils/user";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh"
    },
    image: {
        backgroundImage: `url(${Image})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    copyright: {
        position: "fixed",
        bottom: 10,
        right: 10
    },
    logo: {
        width: "300px",
        marginBottom: "40px"
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        fontWeight: 600
    },
    marginPassword: {
        marginTop: "13px"
    },
    textForgotPassword: {
        textAlign: "left"
    },
    textRegister: {
        float: "right",
    }
}));

const Login = props => {

    const classes = useStyles();
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const goToRegister = event => {
        event.preventDefault();
        history.push("/registro");
    };

    const goToRecoveryPassword = event => {
        event.preventDefault();
        history.push("/recuperar-senha");
    };

    useEffect(() => {
        isLogged() && history.push("/");
    }, []);

    const login = async values => {

        setLoading(true);

        try {
            const res = await new SingInService().post(values);

            if(res.data.active !== 1){
                notification("Ops!", "Usuário inativo entre em contato com o administrador", 
                    "warning");
                setLoading(false);
                return;
            }

            if(res.data.emailVerificated !== 1){
                notification("Atenção!", "É preciso ativar a conta para efetuar o login, verifique sua caixa de entrada", "info");
                setLoading(false);
                return;
            }

            if(values.remember)
                localStorage.setItem(process.env.REACT_APP_KEY, JSON.stringify(res.data));
            else
                sessionStorage.setItem(process.env.REACT_APP_KEY, JSON.stringify(res.data));

            window.location.reload();
            
        } catch(error) {
            
            setLoading(false);

            if(!error.response){
                notification("Falha", "Tente novamente mais tarde", "danger");
                return;
            }

            if(error.response.status === 401){
                notification("Ops!", "Usuário ou senha inválidos", "warning");
                return;
            }
        }
    };

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid 
                item 
                xs={12} 
                sm={8} 
                md={5} 
            >
                <div className={classes.paper}>
                    <img src={Logo} alt="Logo Prefeitura" className={classes.logo} />
                    <Form 
                        className={classes.form}
                        submit={login}
                    >
                        <Input 
                            autoFocus
                            id="email"
                            name="email"
                            label="Email"
                            type="text"
                            variant="outlined"
                            fullWidth
                            rules={{
                                required: true
                            }}
                        />
                        <Input 
                            id="password"
                            type="password"
                            name="password"
                            label="Senha"
                            fullWidth
                            variant="outlined" 
                            className={classes.marginPassword}
                            rules={{
                                required: true
                            }}
                        />
                        <CheckBox 
                            name="remember"
                            label="Lembrar-me"
                            color="primary"
                        />
                        <Button
                            type="submit"
                            label="Entrar"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            loading={loading}
                        />
                        <Grid container>
                            <Grid item xs={6} sm>
                                <Link 
                                    href="#" 
                                    onClick={goToRecoveryPassword} 
                                    className={classes.textForgotPassword}>
                                    Esqueci minha senha
                                </Link>
                            </Grid>
                            <Grid item xs={6} sm>
                                <Link 
                                    href="#" 
                                    onClick={goToRegister} 
                                    className={classes.textRegister}>
                                    Registrar-se
                                </Link>
                            </Grid>
                        </Grid>
                    </Form>
                </div>
                <div className={classes.copyright}>
                    <Copyright />
                </div>
            </Grid>
        </Grid>
    );
};

export default Login;
import React from "react";
import { makeStyles } from "@material-ui/core";
import { MenuItem, TextField } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    formControl: {
        width: "100%"
    }
}));

export default props => {

    const classes = useStyles();
    const { options, label, setOption, value, helperText, setDownload, style } = props;

    
    const handleChange = (event) => {
        setOption(event.target.value);
        setDownload(false);
    };

    return (
        <TextField
            id="outlined-select-currency"
            select
            label={label}
            value={value}
            onChange={handleChange}
            helperText={helperText}
            variant="outlined"
            style={{...style, marginLeft: "20px"}}
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
};
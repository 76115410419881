import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles({
    title: {
        fontSize: "1.5rem",
        fontWeight: 600,
        maxWidth: "600px",
        padding: "100px 30px 0 30px",
        textAlign: "center",
        color: "#ff0000"
    }
});

export default props => {

    const classes = styles();

    return (
        <Grid container justify="center">
            <Typography className={classes.title}>
                Para visualizar o mapa é preciso que não esteje usando o Google Chrome em uma aba anônima.
            </Typography>
        </Grid>
    );
};


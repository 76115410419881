export const showMessage = (rules, name, errors) => {

    if(errors[name]?.type === "required")
        return "Este campo é obrigatório";

    if(errors[name]?.type === "minLength")
        return `Mínimo ${rules["minLength"]} caracteres`;

    if(errors[name]?.type === "maxLength")
        return `Máximo ${rules["maxLength"]} caracteres`;

    if(errors[name]?.type === "validate")
        if(rules.validateType === "CPF")
            return "CPF inválido";
    
    if(errors[name]?.type === "pattern")
        if(rules.validateType === "Email")
            return "Email inválido";

};